import {
    createActionApi,
    createToastOptions,
    InsuranceProductBase,
    InsuranceRequestStage,
    RequestSummaryInfo
} from "../common"
import { RequestModel } from "./types"
import { requestSummaryInfo as requestSummaryInfoFn } from './utils'
import { propertiesSection, proposerSection } from "./uiConfigSections"

export class PublicLiabilityInsuranceProduct extends InsuranceProductBase {
    name = "PublicLiabilityInsurance"
    version = "v1"
    enabled = true
    actionApi = createActionApi("callablePublicLiabilityInsuranceRequestActionFn")
    toastOptions = createToastOptions()

    initialForm() {
        return {
            title: "Public Liability Insurance",
            sections: [
                proposerSection
            ]
        }
    }

    preQuoteView(_stage: InsuranceRequestStage) {
        return {
            title: "Public Liability Insurance",
            sections: [
                proposerSection,
                propertiesSection
            ]
        }
    }

    requestSummaryInfo(data: RequestModel): RequestSummaryInfo {
        return requestSummaryInfoFn(data)
    }
}