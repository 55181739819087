import { useEffect } from 'react'
import { useLocation, useRoute } from 'wouter'
import {
    Accordion,
    Alert,
    AlertIcon,
    Box,
    Center,
    useDisclosure
} from '@chakra-ui/react'
import {
    Main,
    ActionsNavbar,
    SimpleFileUploader
} from '../../../components'
import {
    ConfirmDialog,
    ConfirmDialogProps,
} from '../../../components/ConfirmDialog'
import { useStore } from '../../../store'
import { PartnerQuoteRequestModel } from './types'
import { SimpleAccordionItem } from '../../../products/components/SimpleAccordionItem'
import { groupedDataPointsToTable } from './utils'

export const QuoteAction = () => {
    // eslint-disable-next-line
    const [_location, setLocation] = useLocation()
    // eslint-disable-next-line
    const [_match, params] = useRoute("/q/:id")
    const actionId = params?.id
    const {
        isOpen: confirmDialogIsOpen,
        onOpen: openConfirmDialog,
        onClose: closeConfirmDialog } = useDisclosure()
    const onProceed = () => {
        loadQuoteData()
        closeConfirmDialog()
    }
    const confirmDialogProps: ConfirmDialogProps = {
        headerText: "Confirm Action",
        confirmButtonText: "Yes, Continue",
        bodyText: "Thanks for the acknowledgement. You are about to provide a quote requested via InsureWise, please upload your quote.",
        confirmButtonColorScheme: "green",
        isOpen: confirmDialogIsOpen,
        onClose: closeConfirmDialog,
        onConfirm: onProceed,
    }
    const {
        partnerQuoteData,
        partnerQuoteDataLoading,
        partnerQuoteDataLoadError,
        partnerLoadQuoteData
    } = useStore()

    useEffect(() => {
        openConfirmDialog()
        return () => { }
    }, [actionId, openConfirmDialog])

    if (!actionId) {
        setLocation("/")
        return null
    }

    const loadQuoteData = () => {
        partnerLoadQuoteData(actionId)
    }

    const onUpload = async () => {
        //
    }

    const quoteRequestData = partnerQuoteData as PartnerQuoteRequestModel
    const showError = !partnerQuoteDataLoading && partnerQuoteDataLoadError
    const quoteUploaded = quoteRequestData?.uploadedAt !== undefined
    const showData = !partnerQuoteDataLoading && !partnerQuoteDataLoadError && quoteRequestData?.insuranceType && !quoteUploaded

    return (
        <Main>
            <ActionsNavbar />
            <Box mt="100px" mb={5}>
                {partnerQuoteDataLoading && <Center mt={10}>
                    Loading...
                </Center>}
                {showError && <Center mt={10}>
                    <Alert maxWidth={600} status="error" variant="left-accent">
                        <AlertIcon />
                        Sorry, this request is no longer valid. Thanks!
                    </Alert>
                </Center>}
                {quoteUploaded && <Center mt={10}>
                    <Alert maxWidth={600} status="success" variant="left-accent">
                        <AlertIcon />
                        Quote has already been uploaded, thanks!
                    </Alert>
                </Center>}
                {showData && <Center mt={10}>
                    <Accordion maxWidth={600} allowToggle allowMultiple defaultIndex={[0, 1, 2]} width="full">
                        <SimpleAccordionItem
                            header="Upload Quote Here"
                            body={
                                <SimpleFileUploader
                                    url={partnerQuoteData.quoteUploadUrl}
                                    onUpload={onUpload}
                                />
                            }
                        />
                        <SimpleAccordionItem
                            header="Quote Request Data"
                            body={
                                groupedDataPointsToTable(quoteRequestData.groupedDataPoints)
                            }
                        />
                    </Accordion>
                </Center>}
                <ConfirmDialog
                    {...confirmDialogProps}
                    isOpen={confirmDialogIsOpen}
                />
            </Box>
        </Main>
    )
}
