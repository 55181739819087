import { ReactNode } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react'

interface Props {
  header?: ReactNode
  footer?: ReactNode
}

export type SimpleDrawerProps = Props & DrawerProps

export const SimpleDrawer = ({
  header,
  footer,
  children,
  ...drawerProps
}: SimpleDrawerProps) => {
  return (
    <Drawer {...drawerProps}>
      <DrawerOverlay zIndex={9999}>
        <DrawerContent>
          <DrawerCloseButton />
          {header && <DrawerHeader>{header}</DrawerHeader>}
          <DrawerBody>{children}</DrawerBody>
          {footer && <DrawerFooter>{footer}</DrawerFooter>}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
