import React from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button
} from '@chakra-ui/react'

export interface InfoDialogProps {
    headerText?: string,
    bodyText: string,
    buttonText?: string,
    buttonColorScheme?: string,
    isOpen: boolean,
    onClose: () => void,
}

export const DefaultInfoDialogProps: InfoDialogProps = {
  headerText: "Info",
  buttonText: "Yes",
  bodyText: "Info",
  isOpen: false,
  onClose: () => {},
}

export const InfoDialog = ({
    headerText = "Info",
    bodyText,
    buttonText = "OK",
    buttonColorScheme = "gray",
    isOpen,
    onClose = () => { },
}: InfoDialogProps) => {
    const cancelRef = React.useRef(null);
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {headerText}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {bodyText}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme={buttonColorScheme} ref={cancelRef} onClick={onClose}>
                            {buttonText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
