import {
    addressUIField,
    dateOfBirthUIField,
    emailUIField,
    nameUIField,
    numberUIField,
    textUIField,
    UIConfigField,
} from "../common";

export const allFields: Record<string, UIConfigField> = {
    "proposer_groupName": {
        ...textUIField,
        name: "groupName",
        label: "Company/Group Name",
    },
    "proposer_address": {
        ...addressUIField,
    },
    "proposer_noOfMembers": {
        ...numberUIField,
        name: "noOfMembers",
        label: "No. of Employees/Members",
    },
    "proposer_averageAge": {
        ...numberUIField,
        name: "averageAge",
        label: "Average Age of Employees/Members",
    },
    "proposer_totalAnnualSalary": {
        ...numberUIField,
        name: "totalAnnualSalary",
        label: "Total Annual Salary of Employees/Members",
    },
    "proposer_email": {
        ...emailUIField,
        label: "Company/Group email",
    },
    "member_memberId": {
        ...textUIField,
        name: "memberId",
        label: "Employee/Member ID",
    },
    "member_name": {
        ...nameUIField,
    },
    "member_dateOfBirth": {
        ...dateOfBirthUIField,
    },
    "member_annualSalary": {
        ...numberUIField,
        name: "annualSalary",
        label: "Annual Salary",
    },
}