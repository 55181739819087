import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Heading,
  Icon,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface Props {
  accentColor?: string
  buttonColor?: string
  icon: IconType | undefined,
  header: string
  body: string
  buttonText: string
  onAction: () => void
}

export type SimpleActionCardProps = Props & BoxProps

export const SimpleActionCard = ({
  accentColor = "blue.300",
  buttonColor = "blue",
  icon,
  header,
  body,
  buttonText,
  onAction,
  ...boxProps
}: Props) => {
  return (
    <Box
      shadow="sm"
      width="full"
      borderWidth="1px"
      borderRadius="md"
      p={2}
      // borderLeftWidth={5}
      // borderLeftColor={accentColor}
      {...boxProps}
    >
      <Stack flex="1" pr={2} py={1} pt={1} spacing={1} textAlign="center">
        <Box>
          <Icon color="gray.500" as={icon} w={70} h={70} />
        </Box>
        <Heading fontSize="md" isTruncated>
          {header}
        </Heading>
        <Text>
          {body}
        </Text>
        <Spacer h="auto" />
        <Divider />
        <Spacer />
        <Box>
          <Button colorScheme={buttonColor} variant="outline" onClick={onAction}>
            {buttonText}
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
