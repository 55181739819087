import React from "react"
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    LayoutProps,
    Progress,
    Spacer,
    Text,
    VStack
} from "@chakra-ui/react"
import { DropEvent, useDropzone } from "react-dropzone"
import { FaCloudUploadAlt, FaRedo } from "react-icons/fa"
import axios from "axios"

interface SimpleFileUploaderProps extends LayoutProps {
    url: string,
    onUpload: () => Promise<void>,
    method?: "POST" | "PUT"
    placeholderText?: string
    draggingText?: string
    buttonText?: string
    contentType?: string
}

export const SimpleFileUploader = ({
    url,
    onUpload,
    method = "PUT",
    placeholderText = "Drag and drop file here to upload",
    draggingText = "Drop the files here ...",
    buttonText = "Select file to upload",
    contentType = "application/octet-stream",
    height = "200px",
    ...layoutProps
}: SimpleFileUploaderProps) => {
    const [uploading, setUploading] = React.useState(false)
    const [uploaded, setUploaded] = React.useState(false)
    const [uploadError, setUploadError] = React.useState<any>(undefined)

    const Container = ({
        children
    }: any) => {
        return (
            <VStack
                border="3px #ccc dashed"
                height={height}
                {...layoutProps}
            >
                <Spacer />
                {children}
                <Spacer />
            </VStack>
        )
    }

    const resetUpload = React.useCallback(
        () => {
            setUploading(false)
            setUploaded(false)
            setUploadError(undefined)
        },
        []
    )

    const startUpload = React.useCallback(
        async (file: File) => {
            setUploading(true)

            axios({
                url: url,
                method: method,
                data: file,
                headers: {
                    'Content-Type': contentType
                }
            }).then((value) => {
                if (value.status === 200 || value.status === 201) {
                    setUploaded(true)
                    onUpload()
                }
            }).catch((reason) => {
                setUploadError(reason.toString())
            }).finally(() => {
                setUploading(false)
            })
        },
        [url, method, contentType, onUpload]
    )

    const onDropAccepted = React.useCallback(
        async (files: File[], _event: DropEvent) => {
            await startUpload(files[0])
        },
        [startUpload]
    )

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open: openFilePicker,
    } = useDropzone({
        onDropAccepted,
        noClick: true
    })

    if (uploading) {
        return (
            <Container>
                <Text fontSize="xl">Uploading...</Text>
                <Box width="full" p={2}>
                    <Progress hasStripe isIndeterminate size="lg" width="full" />
                </Box>
            </Container>
        )
    }
    
    if (uploadError) {
        return (
            <Container>
                <Text fontSize="xl">Upload failed</Text>
                <Box width="full" px={2}>
                    <Alert status="error" variant="left-accent">
                        <AlertIcon />
                        {uploadError}
                    </Alert>
                </Box>
                <Button leftIcon={<FaRedo />} onClick={resetUpload}>
                    Retry
                </Button>
            </Container>
        )
    }
    
    if (uploaded) {
        return (
            <Container>
                <Text fontSize="xl">Upload complete</Text>
                <Box width="full" px={2}>
                    <Alert status="success" variant="left-accent">
                        <AlertIcon />
                        The file has been uploaded succesfully
                    </Alert>
                </Box>
            </Container>
        )
    }

    return (
        <VStack
            {...getRootProps()}
            border="3px #ccc dashed"
            height={height}
            {...layoutProps}
        >
            <Spacer />
            <input {...getInputProps()} />
            <FaCloudUploadAlt size="50px" />
            <Text fontSize="md" paddingBottom="0px">
                {isDragActive ? draggingText : placeholderText}
            </Text>
            {
                !isDragActive && <Text fontSize="xs" paddingBottom="0px">
                    - or -
                </Text>
            }
            {
                !isDragActive && <Button onClick={openFilePicker}>
                    {buttonText}
                </Button>
            }
            <Spacer />
        </VStack>
    )
}
