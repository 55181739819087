import React from 'react'
import { useLocation, useRoute } from 'wouter'
import { Center } from '@chakra-ui/react'

export const PartnerAction = () => {
    // eslint-disable-next-line
    const [_location, setLocation] = useLocation()
    // eslint-disable-next-line
    const [_match, params] = useRoute("/actions/partner/:id")
    const actionId = params?.id

    if (!actionId) {
        setLocation("/")
        return null
    }

    return (
        <Center mt={10}>
            Partner action {actionId}
        </Center>
    )
}
