export const missingTextHtml = (value: string): string => {
    return `<span style="color: red;">
        *${value}
      </span>`
}

export {
    uiConfigFieldsGetter,
    uiConfigFieldsToSimpleFormFieldProps,
    uiConfigFieldsToEmptyValues,
    textUIField,
    textareaUIField,
    numberUIField,
    phoneUIField,
    dateUIField,
    selectUIField,
    yesNoUIField,
    emailUIField,
    nameUIField,
    surnameUIField,
    othernamesUIField,
    phoneNoUIField,
    mobileNoUIField,
    addressUIField,
    dateOfBirthUIField,
    genderUIField,
} from './fieldUtils'

export {
    decorateStage,
    createRequestSummaryInfo
} from './stageUtils'
