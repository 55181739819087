import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth, innerHeight } = window;

  return {
    innerWidth,
    innerHeight
  };
}

type UseWindowDimensionsReturnProps = {
  height: number,
  width: number,
}

export const useWindowDimensions = (): UseWindowDimensionsReturnProps => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width: windowDimensions.innerWidth, height: windowDimensions.innerHeight };
}

type UseWindowDimensionsInPxReturnProps = {
  height: string,
  width: string,
}

export const useWindowDimensionsInPx = (): UseWindowDimensionsInPxReturnProps => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const width = `${windowDimensions.innerWidth}px`
  const height = `${windowDimensions.innerHeight}px`

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width, height };
}
