import { useEffect, useState } from 'react'
import {
    Center,
    Heading,
    HStack,
    Spacer,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'
import { useLocation, useRoute } from 'wouter'
import { useSession } from '../../auth'
import { useStore } from '../../store'
import { LoadingFull } from '../../components'
import {
    SimpleForm, SimpleFormFieldProps, SimpleFormProps,
} from '../../components/SimpleForm'
import { SimpleDrawer } from '../../components/SimpleDrawer'
import {
    ConfirmDialog,
    ConfirmDialogProps,
    DefaultConfirmDialogProps,
} from '../../components/ConfirmDialog'
import { UIConfigViewCard } from '../../products/components/UIConfigViewCard'
import { getInsuranceProduct } from '../../products'
import { SubmitButton } from '../../products/components/SubmitButton'
import { WithdrawButton } from '../../products/components/WithdrawButton'
import { createUIConfigDataValidator, decorateStage } from '../../products/common'
import { RequestStageAlert } from '../../products/components/RequestStageAlert'

export default function ViewRequest() {
    // eslint-disable-next-line
    const [_location, setLocation] = useLocation()
    const user = useSession()
    const userId = user?.uid
    // eslint-disable-next-line
    const [_match, params] = useRoute("/requests/:id")
    const requestId = params?.id
    const { insuranceRequests, fetchInsuranceRequest } = useStore()
    const toast = useToast()
    const {
        isOpen: itemDrawerIsOpen,
        onOpen: openItemDrawer,
        onClose: closeItemDrawer } = useDisclosure()
    const [drawerFormProps, setDrawerFormProps] = useState<SimpleFormProps<any>>({
        fields: [],
        initialValues: {},
        onSubmit: (_data) => Promise.resolve(),
        onCancel: () => { },
    })
    const [drawerHeader, setDrawerHeader] = useState("")
    const {
        isOpen: confirmDialogIsOpen,
        onOpen: openConfirmDialog,
        onClose: closeConfirmDialog } = useDisclosure()
    const [confirmProps, setConfirmProps] = useState<ConfirmDialogProps>(DefaultConfirmDialogProps)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isWithdrawing, setIsWithdrawing] = useState(false)
    const [requestData, setRequestData] = useState<any>({})

    useEffect(() => {
        if (!userId) return
        if (!requestId) return

        const data = insuranceRequests[requestId]

        if (data) {
            setRequestData(data)
        } else {
            fetchInsuranceRequest(userId, requestId)
        }

        return () => { }
    }, [userId, requestId, insuranceRequests, fetchInsuranceRequest])

    if (!userId) {
        setLocation("/login")
        return null
    }

    if (!requestId) {
        setLocation("/requests")
        return null
    }

    if (!requestData || requestData.type === undefined) {
        return <LoadingFull />
    }

    const insuranceProduct = getInsuranceProduct(requestData.type)
    const uiConfig = insuranceProduct.preQuoteView(requestData.stage)
    const decoratedStage = decorateStage(requestData.stage)
    const defaultTabIndex: number =
        decoratedStage.showPolicyTab
            ? 3
            : decoratedStage.showPaymentTab
                ? 2
                : decoratedStage.showQuoteTab ? 1 : 0

    const onAddItem = (itemType: string, fields: SimpleFormFieldProps[]) => {
        const formProps: SimpleFormProps<any> = {
            fields: fields,
            initialValues: {},
            submitText: "Save",
            onSubmit: (data) => onCreateItem(itemType, data),
            onCancel: closeItemDrawer
        }
        setDrawerHeader(`Add New ${itemType}`)
        setDrawerFormProps(formProps)
        openItemDrawer()
    }

    const onEditItem = (itemType: string, fields: SimpleFormFieldProps[], itemData: any) => {
        const formProps: SimpleFormProps<any> = {
            fields: fields,
            initialValues: itemData,
            submitText: "Update",
            onSubmit: (data) => onUpdateItem(itemType, data),
            onCancel: closeItemDrawer
        }
        setDrawerHeader(`View/Edit ${itemType}`)
        setDrawerFormProps(formProps)
        openItemDrawer()
    }

    const onCreateItem = async (itemType: string, itemData: any) => {
        try {
            await insuranceProduct.actionApi.updateRequestItem(requestId, itemType, itemData)
            fetchInsuranceRequest(userId, requestId)
            closeItemDrawer()
            toast(insuranceProduct.toastOptions.itemUpdateSuccess(itemType))
        } catch (error) {
            toast(insuranceProduct.toastOptions.itemUpdateError(itemType, error))
        }
    }

    const onUpdateItem = async (itemType: string, itemData: any) => {
        try {
            await insuranceProduct.actionApi.updateRequestItem(requestId, itemType, itemData)
            fetchInsuranceRequest(userId, requestId)
            closeItemDrawer()
            toast(insuranceProduct.toastOptions.itemUpdateSuccess(itemType))
        } catch (error) {
            toast(insuranceProduct.toastOptions.itemUpdateError(itemType, error))
        }
    }

    const onRemoveItem = async (itemType: string, itemData: any) => {
        const submitConfirmProps: ConfirmDialogProps = {
            headerText: "Confirm Removal",
            confirmButtonText: "Yes, Remove!",
            bodyText: `Are you sure you want to Remove this ${itemType}?`,
            isOpen: confirmDialogIsOpen,
            onClose: closeConfirmDialog,
            onConfirm: async () => {
                try {
                    await insuranceProduct.actionApi.deleteRequestItem(
                        requestId,
                        itemType,
                        itemData)
                    fetchInsuranceRequest(userId, requestId)
                    closeConfirmDialog()
                    toast(insuranceProduct.toastOptions.itemDeleteSuccess(itemType))
                } catch (error) {
                    toast(insuranceProduct.toastOptions.itemDeleteError(itemType, error))
                }
            },
        }
        setConfirmProps(submitConfirmProps)
        openConfirmDialog()
    }

    const requestDataIsValid = (): boolean => {
        const validator = uiConfig.validator || createUIConfigDataValidator(uiConfig)
        return validator(requestData)
    }

    const onSubmitRequest = async () => {
        const submitConfirmProps: ConfirmDialogProps = {
            headerText: "Confirm Submission",
            confirmButtonText: "Yes, Submit!",
            bodyText: "Are you sure you want to Submit your request?",
            cancelButtonText: "Not yet",
            confirmButtonColorScheme: "green",
            cancelButtonColorScheme: "gray",
            isOpen: confirmDialogIsOpen,
            onClose: closeConfirmDialog,
            onConfirm: async () => {
                closeConfirmDialog()

                if (!requestDataIsValid()) {
                    toast(insuranceProduct.toastOptions.invalidRequestWarning(requestData))
                    return
                }

                try {
                    setIsSubmitting(true)
                    await insuranceProduct.actionApi.submitRequest(requestId)
                    fetchInsuranceRequest(userId, requestId)
                    toast(insuranceProduct.toastOptions.submitSuccess())
                } catch (error) {
                    toast(insuranceProduct.toastOptions.submitError(error))
                } finally {
                    setIsSubmitting(false)
                }
            },
        }
        setConfirmProps(submitConfirmProps)
        openConfirmDialog()
    }

    const onWithdrawRequest = async () => {
        const withdrawConfirmProps: ConfirmDialogProps = {
            headerText: "Confirm Withdrawal",
            confirmButtonText: "Withdraw",
            bodyText: "Are you sure you want to Withdraw your request?",
            cancelButtonText: "No, go back",
            isOpen: confirmDialogIsOpen,
            onClose: closeConfirmDialog,
            onConfirm: async () => {
                closeConfirmDialog()

                try {
                    setIsWithdrawing(true)
                    await insuranceProduct.actionApi.withdrawRequest(requestId)
                    toast(insuranceProduct.toastOptions.withdrawSuccess())
                } catch (error) {
                    toast(insuranceProduct.toastOptions.withdrawError(error))
                } finally {
                    setIsWithdrawing(false)
                }
            },
        }
        setConfirmProps(withdrawConfirmProps)
        openConfirmDialog()
    }

    return (
        <Center>
            <Stack maxWidth={600} flex="1" px={0} py={1} spacing={1}>
                <Heading fontSize="lg" isTruncated>
                    {uiConfig.title} &nbsp;&nbsp;
                </Heading>
                <Tabs isFitted defaultIndex={defaultTabIndex}>
                    <TabList>
                        <Tab>1. Apply</Tab>
                        <Tab isDisabled={!decoratedStage.showQuoteTab}>2. Quote</Tab>
                        <Tab isDisabled={!decoratedStage.showPaymentTab}>3. Pay</Tab>
                        <Tab isDisabled={!decoratedStage.showPolicyTab}>4. Policy</Tab>
                        <Tab isDisabled={!decoratedStage.showPolicyTab}>5. Claim</Tab>
                    </TabList>
                    <RequestStageAlert mt={2} decoratedStage={decoratedStage} />
                    <TabPanels>
                        <TabPanel px={0}>
                            <UIConfigViewCard
                                uiConfig={uiConfig}
                                record={requestData}
                                onAdd={onAddItem}
                                onEdit={onEditItem}
                                onRemove={onRemoveItem}
                            />
                            <Spacer height={4} />
                            <HStack hidden={!decoratedStage.isEditable}>
                                <Spacer />
                                <SubmitButton
                                    isSubmitting={isSubmitting}
                                    isWithdrawing={isWithdrawing}
                                    onClick={onSubmitRequest}
                                />
                                <WithdrawButton
                                    isSubmitting={isSubmitting}
                                    isWithdrawing={isWithdrawing}
                                    onClick={onWithdrawRequest}
                                />
                                <Spacer />
                            </HStack>
                        </TabPanel>
                        <TabPanel px={0}>Quote coming soon</TabPanel>
                        <TabPanel px={0}>Payment coming soon</TabPanel>
                        <TabPanel px={0}>Policy coming soon</TabPanel>
                        <TabPanel px={0}>Claim coming soon</TabPanel>
                    </TabPanels>
                </Tabs>
            </Stack>
            <SimpleDrawer
                isOpen={itemDrawerIsOpen}
                onClose={closeItemDrawer}
                header={drawerHeader}>
                <SimpleForm<any>
                    fields={drawerFormProps.fields}
                    initialValues={drawerFormProps.initialValues}
                    onSubmit={drawerFormProps.onSubmit}
                    onCancel={closeItemDrawer}
                    submitText={drawerFormProps.submitText}
                />
            </SimpleDrawer>
            <ConfirmDialog
                {...confirmProps}
                isOpen={confirmDialogIsOpen}
            />
        </Center>
    )
}
