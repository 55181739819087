import { SimpleFormFieldProps } from '../../components/SimpleForm'
import { InviteRequestModel } from '../../types'

export const inviteFields: SimpleFormFieldProps[] = [
  {
    name: "inviterName",
    label: "Your name",
    type: "text",
    placeholder: "Your name",
    isRequired: true,
  },
  {
    name: "inviteeName",
    label: "Your friend's name",
    type: "text",
    placeholder: "Friend's name",
    isRequired: true,
  },
  {
    name: "inviteeEmail",
    label: "Your friend's email",
    type: "email",
    placeholder: "Friend's email",
    isRequired: true,
  },
]

export const inviteInitialValues: InviteRequestModel = {
  inviterName: "",
  inviteeName: "",
  inviteeEmail: "",
}
