import {
    createActionApi,
    createToastOptions,
    InsuranceProductBase,
    InsuranceRequestStage,
    RequestSummaryInfo
} from "../common"
import { RequestModel } from "./types"
import { requestSummaryInfo as requestSummaryInfoFn } from './utils'
import {
    driversSection,
    initialYourInfoSection,
    initialVehicleInfoSection,
    proposerSection,
    vehiclesSection
} from "./uiConfigSections"

export class AutoInsuranceProduct extends InsuranceProductBase {
    name = "AutoInsurance"
    version = "v1"
    enabled = true
    actionApi = createActionApi("callableAutoInsuranceRequestActionFn")
    toastOptions = createToastOptions()

    initialForm() {
        return {
            title: "Auto Insurance",
            sections: [
                initialYourInfoSection,
                initialVehicleInfoSection,
            ]
        }
    }

    preQuoteView(_stage: InsuranceRequestStage) {
        return {
            title: "Auto Insurance",
            sections: [
                proposerSection,
                vehiclesSection,
                driversSection,
            ]
        }
    }

    requestSummaryInfo(data: RequestModel): RequestSummaryInfo {
        return requestSummaryInfoFn(data)
    }
}