import { Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Box } from '@chakra-ui/react'
import { RequestStageDecorator } from '../common'

interface Props extends AlertProps {
    decoratedStage: RequestStageDecorator
}

export const RequestStageAlert = ({ decoratedStage, ...alertProps }: Props) => {
    return (
        <Alert {...alertProps} status={decoratedStage.alertStatus} variant="left-accent">
            <AlertIcon />
            <Box flex="1">
                <AlertTitle mr={2}>{decoratedStage.label}</AlertTitle>
                <AlertDescription>{decoratedStage.nextStage}</AlertDescription>
            </Box>
        </Alert>
    )
}
