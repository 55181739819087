import { Box } from '@chakra-ui/react'
import { Route, Switch } from 'wouter'
import { DefaultNavbar, Main } from '../components'
import Dashboard from './dashboard/Index'
import Requests from './requests/Index'
import Claims from './claims/Index'
import { useIsDev } from '../hooks'
import { db, functions } from '../firebase'

export default function DefaultLayout() {
  const isDev = useIsDev()

  if (isDev) {
    db.settings({ host: "localhost:8080", ssl: false });
    functions.useFunctionsEmulator("http://localhost:5001")
  }

  return (
    <Main>
      <DefaultNavbar />
      <Box mt={90} mb={150}>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/requests/:rest*" component={Requests} />
          <Route path="/r/:rest*" component={Requests} />
          <Route path="/claims/:rest*" component={Claims} />
          <Route>
            <Dashboard />
          </Route>
        </Switch>
      </Box>
    </Main>
  )
}
