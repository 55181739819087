import { Flex, Stack, Accordion, VStack, Button, HStack, Spacer } from '@chakra-ui/react'
import { createUIConfigSectionDataValidator, decorateStage, UIConfig, uiConfigFieldsToEmptyValues, uiConfigFieldsToSimpleFormFieldProps, UIConfigSection, UIConfigSectionCollection } from '../common'
import { SimpleAccordionItem, SimpleAccordionItemProps } from './SimpleAccordionItem'
import { SimpleFormFieldProps, SimpleFormViewer } from '../../components/SimpleForm'
import { FaPlus } from 'react-icons/fa'
import { UIConfigSectionCollectionCard } from './UIConfigSectionCollectionCard'

interface Props {
    uiConfig: UIConfig
    record?: any
    colorScheme?: string
    onAdd?: (sectionName: string, fields: SimpleFormFieldProps[]) => void
    onEdit?: (sectionName: string, fields: SimpleFormFieldProps[], data: any) => void
    onRemove?: (sectionName: string, data: any) => void
}

const getSectionHeader = (section: UIConfigSection, record: any): string => {
    if (typeof section.title === "function") {
        return section.title(record)
    }

    return section.title
}

const getSectionEditable = (section: UIConfigSection, record: any): boolean => {
    if (typeof section.editable === "function") {
        return section.editable(record)
    }

    return section.editable
}

export const UIConfigViewCard = ({
    uiConfig,
    record = {},
    onAdd = (_sectionName: string, _fields: SimpleFormFieldProps[]) => { },
    onEdit = (_sectionName: string, _fields: SimpleFormFieldProps[], _data: any) => { },
    onRemove = (_sectionName: string, _data: any) => { },
}: Props) => {
    const stage = decorateStage(record.stage)
    const itemDataIsValid = (section: UIConfigSection, data: any): boolean => {
        const validator = section.validator || createUIConfigSectionDataValidator(section)
        return validator(data, record)
    }

    const accordionItemsProps: (SimpleAccordionItemProps | undefined)[] =
        uiConfig.sections.map(section => {
            const isEditable = stage.isEditable && getSectionEditable(section, record)
            const fields = uiConfigFieldsToSimpleFormFieldProps(section.fields, record)
            const defaultValues = uiConfigFieldsToEmptyValues(section.fields)
            const props: SimpleAccordionItemProps = {
                header: getSectionHeader(section, record), body: null
            }

            if ((section as UIConfigSectionCollection).keyPropertyName) {
                const dataRows: any[] = Object.values(record[section.dataKey] || {})
                if (!stage.isEditable && dataRows.length === 0) {
                    return undefined
                }

                const keyPropertyName = (section as UIConfigSectionCollection).keyPropertyName

                props.body = (
                    <VStack>
                        {
                            dataRows.map((itemData, index) => {
                                return (
                                    <UIConfigSectionCollectionCard
                                        key={`${index}-${keyPropertyName}`}
                                        uiConfigSection={section as UIConfigSectionCollection}
                                        fields={fields}
                                        data={itemData}
                                        isEditable={isEditable}
                                        isExpanded={dataRows.length === 1}
                                        isValid={itemDataIsValid(section, itemData)}
                                        onEdit={() => onEdit(section.name, fields, { ...defaultValues, ...itemData })}
                                        onDelete={() => onRemove(section.name, itemData)}
                                    />
                                )
                            })
                        }
                        {
                            isEditable && <HStack width="full">
                                <Spacer />
                                <Button
                                    leftIcon={<FaPlus />}
                                    colorScheme="blue"
                                    variant="outline"
                                    onClick={() => onAdd(section.name, fields)}
                                >
                                    Add {section.name}
                                </Button>
                            </HStack>
                        }
                    </VStack>
                )
            } else {
                const data = record[section.dataKey] || {}
                const values = { ...defaultValues, ...data }
                props.editOnClick = isEditable ? () => onEdit(section.name, fields, data) : undefined
                props.body = (
                    <SimpleFormViewer
                        fields={fields}
                        values={values}
                    />
                )
            }

            return props
        })
    const validAccordionItemsProps: SimpleAccordionItemProps[] =
        accordionItemsProps.filter((e): e is SimpleAccordionItemProps => !!e)

    return (
        <Flex width="full">
            <Stack flex="1" px={0} py={1} spacing={1} width="full">
                <Accordion allowToggle allowMultiple defaultIndex={[0, 1, 2, 3, 4]} width="full">
                    {
                        validAccordionItemsProps.map(itemProps => {
                            return (
                                <SimpleAccordionItem key={itemProps.header} {...itemProps} />
                            )
                        })
                    }
                </Accordion>
            </Stack>
        </Flex>
    )
}
