import { BiBuildingHouse } from "react-icons/bi"
import { FaCar, FaLuggageCart, FaPeopleCarry } from "react-icons/fa"
import { GiWildfires } from "react-icons/gi"
import { HiUserGroup } from "react-icons/hi"
import { TiGroup } from "react-icons/ti"
import { SimpleActionCardProps } from "../../components/SimpleActionCard"
import { InsuranceProductType } from "../../products/common"

interface Props extends Omit<SimpleActionCardProps, "onAction" | "buttonText"> {
    type: InsuranceProductType
}

export const insuranceRequestCardsProps: Props[] = [
    {
        icon: FaCar,
        type: "AutoInsurance",
        header: "Auto Insurance",
        body: "It covers the costs if you cause a car accident that injures someone else or damages their property. It can also cover damage to your own car, either from a collision or another type of danger, like hail, fire, falling objects and theft.",
    },
    {
        icon: HiUserGroup,
        type: "GroupComprehensiveLifeInsurance",
        header: "Group Comprehensive Life Insurance",
        body: "Group insurance is an insurance that covers a group of people, for example the members of a society or professional association, or the employees of an organisation or firm.",
    },
    {
        icon: TiGroup,
        type: "GroupPersonalAccidentInsurance",
        header: "Group Personal Accident Insurance",
        body: "Group insurance is an insurance that covers a group of people, for example the members of a society or professional association, or the employees of an organisation or firm.",
    },
    {
        icon: FaPeopleCarry,
        type: "WorkmenCompensationInsurance",
        header: "Workmen's Compensation Insurance",
        body: "Group insurance is an insurance that covers a group of people, for example the members of a society or professional association, or the employees of an organisation or firm.",
    },
    {
        icon: GiWildfires,
        type: "FireInsurance",
        header: "Fire Insurance",
        body: "Fire insurance is a property coverage that pays for damages to property and other losses you may suffer from a fire. It covers the cost of repairing or replacing damaged property in your home, along with the costs of accommodations while your home is unusable.",
    },
    {
        icon: BiBuildingHouse,
        type: "PublicLiabilityInsurance",
        header: "Public Liability Insurance",
        body: "Public liability helps cover the costs for personal injuries, loss or damage to property, and death on your business property.",
    },
    {
        icon: FaLuggageCart,
        type: "TravelInsurance",
        header: "Travel Insurance",
        body: "Cover unforeseen losses incurred while travelling, either internationally or domestically. Basic policies generally only cover emergency medical expenses while overseas, while comprehensive policies typically include coverage for trip cancellation, lost luggage, flight delays, public liability, and other expenses.",
    },
]