import React, { ReactNode } from 'react'
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Text,
} from '@chakra-ui/react'
import { FaEdit } from 'react-icons/fa'

export interface SimpleAccordionItemProps {
    editOnClick?: () => void,
    header: string,
    body: ReactNode,
    visibility?: "visible" | "hidden"
}

export const SimpleAccordionItem = ({
    editOnClick,
    header,
    body,
    visibility = "visible"
}: SimpleAccordionItemProps) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        editOnClick && editOnClick()
    }

    return (
        <AccordionItem visibility={visibility} position="relative">
            <AccordionButton>
                <Box flex="1" textAlign="left">
                    <Text textAlign="center" fontSize="md" fontWeight="600" ml={1}>
                        {header}
                    </Text>
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} px={1}>
                {body}
            </AccordionPanel>
            {editOnClick && <Button
                size="sm"
                position="absolute"
                top="1.5"
                left="3"
                aria-label="Edit"
                leftIcon={<FaEdit />}
                onClick={onClick}
            >Edit</Button>}
        </AccordionItem>
    )
}
