import { missingTextHtml, uiConfigFieldsGetter, UIConfigSection } from "../common"
import { allFields } from "./uiConfigFields"

export const getFields = uiConfigFieldsGetter(allFields)

export const initailProposerSection: UIConfigSection = {
    title: "PROPOSER INFORMATION",
    name: "Proposer",
    dataKey: "proposer",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_name",
            "proposer_address",
            "proposer_phoneNo",
            "proposer_faxNo",
            "proposer_nationality",
            "proposer_email",
        ])
    ]
}

export const initailBuildingsSection: UIConfigSection = {
    title: "BUIDLINGS INFORMATION",
    name: "Building",
    dataKey: "buildings",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_noOfPrivateBuildings",
            "proposer_noOfBusinessBuildings",
            "proposer_occupationOrBusiness",
        ])
    ]
}

export const proposerSection: UIConfigSection = {
    title: "PROPOSER INFORMATION",
    name: "Proposer",
    dataKey: "proposer",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_name",
            "proposer_address",
            "proposer_phoneNo",
            "proposer_faxNo",
            "proposer_occupationOrBusiness",
            "proposer_nationality",
            "proposer_email"
        ])
    ]
}

export const buildingsSection: UIConfigSection = {
    title: "BUILDINGS",
    name: "Building",
    dataKey: "buildings",
    visible: true,
    editable: true,
    headerText: (data: any) => `${data.premiseType} - ${data.location || "Unknown location"}`,
    collapsedHtml: (data: any) => `
        <b>Location:</b> ${data.location || missingTextHtml("Location missing")}
        <br/>
        <b>Sum Insurred:</b> ${data.sumInsured || missingTextHtml("Sum Insured missing")}
    `,
    keyPropertyName: "buildingId",
    fields: [
        ...getFields([
            "building_buildingId",
            "building_location",
            "building_builtWith",
            "building_roofedWith",
            "building_sumInsured",
            "building_premiseType"
        ])
    ]
}
