import { functions } from "../../firebase"
import { InsuranceProductActionApi } from "./types"

export const createActionApi = (callableFn: string): InsuranceProductActionApi => {
    const actionApi: InsuranceProductActionApi = {
        async runAction(actionName: string, requestData: any) {
            const actionFn = functions.httpsCallable(callableFn)
            return actionFn({ actionName: actionName, requestData: requestData })
        },
        async createRequest(data: any): Promise<string> {
            try {
                const result = await this.runAction("Create", data)
                return result.data.success
                    ? Promise.resolve(result.data.id)
                    : Promise.reject(result.data.error)
            } catch (error) {
                console.log(error)
                return Promise.reject()
            }
        },
        async updateRequest(requestId: string, data: any): Promise<string> {
            try {
                const result = await this.runAction("Update", {
                    ...data, requestId: requestId
                })
                return result.data.success
                    ? Promise.resolve(result.data.id)
                    : Promise.reject(result.data.error)
            } catch (error) {
                console.log(error)
                return Promise.reject()
            }
        },
        async updateRequestItem(requestId: string, itemType: string, itemData: any): Promise<any> {
            try {
                const result = await this.runAction(`Update${itemType}`, {
                    ...itemData, requestId: requestId
                })
                return result.data.success
                    ? Promise.resolve(result.data.id)
                    : Promise.reject(result.data.error)
            } catch (error) {
                console.log(error)
                return Promise.reject()
            }
        },
        async deleteRequestItem(requestId: string, itemType: string, itemData: any): Promise<any> {
            try {
                const result = await this.runAction(`Delete${itemType}`, {
                    ...itemData, requestId: requestId
                })
                return result.data.success
                    ? Promise.resolve(result.data.id)
                    : Promise.reject(result.data.error)
            } catch (error) {
                console.log(error)
                return Promise.reject()
            }
        },
        async submitRequest(requestId: string): Promise<any> {
            try {
                const result = await this.runAction("Submit", { requestId: requestId })
                return result.data.success
                    ? Promise.resolve(result.data.id)
                    : Promise.reject(result.data.error)
            } catch (error) {
                console.log(error)
                return Promise.reject()
            }
        },
        async withdrawRequest(requestId: string): Promise<any> {
            try {
                const result = await this.runAction("Withdraw", { requestId: requestId })
                return result.data.success
                    ? Promise.resolve(result.data.id)
                    : Promise.reject(result.data.error)
            } catch (error) {
                console.log(error)
                return Promise.reject()
            }
        }
    }

    return actionApi
}