import {
    SimpleFormFieldProps,
    SimpleFormSelectFieldProps,
    SimpleFormInputFieldProps
} from "../../../components/SimpleForm"
import { UIConfigField, UIFieldType, DBDataType } from "../types"

const nullUIConfigField: UIConfigField = {
    name: "Undefined",
    label: "Undefined",
    required: false,
    fieldType: UIFieldType.text,
    dataType: DBDataType.string,
    placeholder: "Error field",
    helperText: "Error field",
}

export const uiConfigFieldsGetter = (
    fields: Record<string, UIConfigField>
): (fieldNames: string[]) => UIConfigField[] => {
    return (fieldNames: string[]): UIConfigField[] => {
        return fieldNames.map(name => fields[name] || nullUIConfigField)
    }
}

type SimpleInputType = "text" | "number" | "email" | "phone" | "textarea" | "date"

const getSimpleInputType = (uiFieldType: UIFieldType): SimpleInputType => {
    const data: Record<UIFieldType, SimpleInputType> = {
        [UIFieldType.text]: "text",
        [UIFieldType.number]: "number",
        [UIFieldType.email]: "email",
        [UIFieldType.phone]: "phone",
        [UIFieldType.textarea]: "textarea",
        [UIFieldType.date]: "date",
        [UIFieldType.select]: "text",
    }

    return data[uiFieldType]
}

const getFieldRequired = (uiConfigField: UIConfigField, record: any): boolean => {
    if (typeof uiConfigField.required === "boolean") {
        return uiConfigField.required
    } else if (typeof uiConfigField.required === "function") {
        uiConfigField.required(record)
    }

    return false
}

const getFieldHidden = (uiConfigField: UIConfigField, record: any): boolean => {
    if (typeof uiConfigField.hidden === "boolean") {
        return uiConfigField.hidden
    } else if (typeof uiConfigField.hidden === "function") {
        uiConfigField.hidden(record)
    }

    return false
}

export const uiConfigFieldsToSimpleFormFieldProps = (uiConfigFields: UIConfigField[], record: any): SimpleFormFieldProps[] => {
    const fields: SimpleFormFieldProps[] = []
    uiConfigFields.forEach(uiConfigField => {
        if (getFieldHidden(uiConfigField, record)) return

        const isRequired = getFieldRequired(uiConfigField, record)
        if (uiConfigField.fieldType === UIFieldType.select) {
            const fieldProps: SimpleFormSelectFieldProps = {
                name: uiConfigField.dataKey || uiConfigField.name,
                label: uiConfigField.label,
                isRequired: isRequired,
                data: uiConfigField.selectData || [],
                placeholder: uiConfigField.placeholder,
                helperText: uiConfigField.helperText,
            }
            fields.push(fieldProps)
        } else {
            const fieldProps: SimpleFormInputFieldProps = {
                name: uiConfigField.dataKey || uiConfigField.name,
                label: uiConfigField.label,
                type: getSimpleInputType(uiConfigField.fieldType),
                isRequired: isRequired,
                placeholder: uiConfigField.placeholder,
                helperText: uiConfigField.helperText,
            }
            fields.push(fieldProps)
        }
    })

    return fields
}

export const uiConfigFieldsToEmptyValues = (uiConfigFields: UIConfigField[]): Record<string, any> => {
    const defaultValues: Record<string, any> = {}

    uiConfigFields.forEach(uiConfigField => {
        defaultValues[uiConfigField.name] = ""
    })

    return defaultValues
}

export const textUIField: Omit<UIConfigField, "name" | "label"> = {
    fieldType: UIFieldType.text,
    dataType: DBDataType.string,
    required: true
}

export const textareaUIField: Omit<UIConfigField, "name" | "label"> = {
    fieldType: UIFieldType.textarea,
    dataType: DBDataType.string,
    required: true
}

export const numberUIField: Omit<UIConfigField, "name" | "label"> = {
    fieldType: UIFieldType.number,
    dataType: DBDataType.number,
    required: true
}

export const phoneUIField: Omit<UIConfigField, "name" | "label"> = {
    fieldType: UIFieldType.phone,
    dataType: DBDataType.string,
    required: true
}

export const dateUIField: Omit<UIConfigField, "name" | "label"> = {
    fieldType: UIFieldType.date,
    dataType: DBDataType.timestamp,
    required: true
}

export const selectUIField: Omit<UIConfigField, "name" | "label"> = {
    fieldType: UIFieldType.select,
    dataType: DBDataType.string,
    required: true
}

export const yesNoUIField: Omit<UIConfigField, "name" | "label"> = {
    ...selectUIField,
    selectData: [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" },
    ]
}

// Specific fields

export const emailUIField: UIConfigField = {
    name: "email",
    label: "Email",
    fieldType: UIFieldType.email,
    dataType: DBDataType.string,
    required: true
}

export const nameUIField: UIConfigField = {
    ...textUIField,
    name: "name",
    label: "Name",
}

export const surnameUIField: UIConfigField = {
    ...textUIField,
    name: "surname",
    label: "Surname",
}

export const othernamesUIField: UIConfigField = {
    ...textUIField,
    name: "othernames",
    label: "Other Names",
}

export const phoneNoUIField: UIConfigField = {
    ...phoneUIField,
    name: "phoneNo",
    label: "Phone Number",
}

export const mobileNoUIField: UIConfigField = {
    ...phoneUIField,
    name: "mobileNo",
    label: "Mobile Number",
}

export const addressUIField: UIConfigField = {
    ...textUIField,
    name: "address",
    label: "Address",
}

export const dateOfBirthUIField: UIConfigField = {
    name: "dateOfBirth",
    label: "Date Of Birth",
    fieldType: UIFieldType.date,
    dataType: DBDataType.timestamp,
    required: true
}

export const genderUIField: UIConfigField = {
    ...selectUIField,
    name: "gender",
    label: "Gender",
    selectData: [
        { key: "Male", value: "Male" },
        { key: "Female", value: "Female" },
    ]
}