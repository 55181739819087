import React from 'react'
import { Button } from '@chakra-ui/react'
import { FaThumbsUp } from 'react-icons/fa'

interface Props {
    isSubmitting: boolean
    isWithdrawing: boolean
    onClick: () => void
}

export const SubmitButton = ({
    isSubmitting,
    isWithdrawing,
    onClick
}: Props) => {
    return (
        <Button
            leftIcon={<FaThumbsUp />}
            colorScheme="green"
            size="lg"
            isLoading={isSubmitting}
            loadingText="Submitting your request"
            hidden={isWithdrawing}
            onClick={onClick}
        >
            Submit
        </Button>
    )
}
