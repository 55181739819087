import { useState } from 'react'
import { IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { MdDeleteForever, MdEdit, MdMoreVert, MdUnfoldLess, MdUnfoldMore } from 'react-icons/md'

interface Props {
    isExpanded: boolean
    isEditable: boolean
    onExpand: () => void
    onCollapse: () => void
    onEdit: () => void
    onDelete: () => void
}

export const CardActionButtons = ({
    isExpanded,
    isEditable,
    onExpand,
    onCollapse,
    onEdit,
    onDelete
}: Props) => {
    const [expanded, setExpanded] = useState(isExpanded)

    const onToggle = () => {
        expanded ? onCollapse() : onExpand()
        setExpanded(!expanded)
    }

    return (
        <>
            <IconButton
                variant="outline"
                aria-label={expanded ? "Collapse" : "Expand"}
                rounded="md"
                icon={expanded ?<MdUnfoldLess /> : <MdUnfoldMore />}
                onClick={onToggle}
            />
            {isEditable && <Menu placement="bottom-end">
                <MenuButton
                    as={IconButton}
                    ml={1}
                    variant="outline"
                    aria-label="More actions"
                    rounded="md"
                    icon={<MdMoreVert />}
                />
                <MenuList>
                    <MenuItem onClick={onEdit}>
                        <MdEdit />
                        <span style={{ marginLeft: "10px" }}>View/Edit</span>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={onDelete}>
                        <MdDeleteForever />
                        <span style={{ marginLeft: "10px" }}>Remove</span>
                    </MenuItem>
                </MenuList>
            </Menu>}
        </>
    )
}
