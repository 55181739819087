import { missingTextHtml, uiConfigFieldsGetter, UIConfigSection } from "../common"
import { allFields } from "./uiConfigFields"

export const getFields = uiConfigFieldsGetter(allFields)

export const proposerSection: UIConfigSection = {
    title: "PROPOSER INFORMATION",
    name: "Proposer",
    dataKey: "proposer",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_name",
            "proposer_address",
            "proposer_phoneNo",
            "proposer_businessOrTrade"
        ])
    ]
}

export const propertiesSection: UIConfigSection = {
    title: "PROPERTIES",
    name: "Property",
    dataKey: "properties",
    visible: true,
    editable: true,
    headerText: (data: any) => `# ${data.propertyId || "Unknown"}`,
    collapsedHtml: (data: any) => `
        <b>Address:</b> ${data.address || missingTextHtml("Address missing")}
        <br/>
        <b>Indemnity for any one accident:</b> ${data.indemnityForOneAccident || missingTextHtml("Missing")}
    `,
    keyPropertyName: "propertyId",
    fields: [
        ...getFields([
            "property_propertyId",
            "property_indemnityForOneAccident",
        ])
    ]
}
