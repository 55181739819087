import { Center, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import ReactJson from 'react-json-view'
import { CopyBlock, github } from 'react-code-blocks'
import { useRoute } from 'wouter'
import { getInsuranceProduct } from '../../products'
import { UIConfigField, UIConfigSectionCollection } from '../../products/common'
import { getInterfaceDefinition, transformSection } from './utils'

export default function Index() {
    // eslint-disable-next-line
    const [_match, params] = useRoute("/explain/:name")
    const name = params?.name

    if (!name) return null

    const insuranceProduct = getInsuranceProduct(name)

    const explainDefinition = (): object => {
        return {
            name: insuranceProduct.name,
            version: insuranceProduct.version,
            enabled: insuranceProduct.enabled,
            initialSections: insuranceProduct
                .initialForm()
                .sections
                .reduce((obj, section) => ({
                    ...obj,
                    [section.name]: transformSection(section, true)
                }), {}),
            preQuoteSections: insuranceProduct
                .preQuoteView('In Progress')
                .sections
                .reduce((obj, section) => ({
                    ...obj,
                    [section.name]: transformSection(section)
                }), {}),
        }
    }

    const explainTypes = (): string => {
        const interfaceDefs: string[] = []

        interfaceDefs.push(`import { InsuranceRequestModel } from "../../common/types"`)

        const initialFields: UIConfigField[] =
            insuranceProduct.initialForm()
                .sections.flatMap(s => s.fields)

        interfaceDefs.push(
            getInterfaceDefinition("InitialRequestModel", initialFields)
        )

        insuranceProduct.preQuoteView('In Progress').sections.forEach(section => {
            interfaceDefs.push(
                getInterfaceDefinition(`Request${section.name}Model`, section.fields)
            )
        })

        const requestModelInterfaceTokens: string[] = []

        requestModelInterfaceTokens.push(`export interface RequestModel  extends InsuranceRequestModel {`)

        insuranceProduct.preQuoteView('In Progress').sections.forEach(section => {
            if ((section as UIConfigSectionCollection).keyPropertyName) {
                requestModelInterfaceTokens.push(
                    `    ${section.dataKey}: Record<string, Request${section.name}Model>`)
            } else {
                requestModelInterfaceTokens.push(
                    `    ${section.dataKey}: Request${section.name}Model`)
            }
        })

        requestModelInterfaceTokens.push(`}`)

        interfaceDefs.push(requestModelInterfaceTokens.join("\n"))

        return interfaceDefs.join("\n\n")
    }

    return (
        <Center>
            <Tabs isFitted maxWidth={600} mb={5}>
                <TabList mb="1em">
                    <Tab>Definition</Tab>
                    <Tab>Types</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ReactJson src={explainDefinition()} />
                    </TabPanel>
                    <TabPanel fontSize="medium" fontFamily="monospace">
                        <CopyBlock
                            text={explainTypes()}
                            language="javascript"
                            theme={github}
                            wrapLines={true}
                            codeBlock
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Center>
    )
}
