import { InsuranceProduct, NullInsuranceProduct } from "./common";
import { GroupComprehensiveLifeInsuranceProduct } from "./GroupComprehensiveLifeInsurance";
import { GroupPersonalAccidentInsuranceProduct } from "./GroupPersonalAccidentInsurance";
import { WorkmenCompensationInsuranceProduct } from "./WorkmenCompensationInsurance";
import { TravelInsuranceProduct } from "./TravelInsurance";
import { AutoInsuranceProduct } from "./AutoInsurance";
import { FireInsuranceProduct } from "./FireInsurance";
import { PublicLiabilityInsuranceProduct } from "./PublicLiabilityInsurance";

export const getInsuranceProduct = (
    productName: string,
    _version?: string
): InsuranceProduct => {
    if (productName === "AutoInsurance") {
        return new AutoInsuranceProduct()
    } else if (productName === "GroupComprehensiveLifeInsurance") {
        return new GroupComprehensiveLifeInsuranceProduct()
    } else if (productName === "GroupPersonalAccidentInsurance") {
        return new GroupPersonalAccidentInsuranceProduct()
    } else if (productName === "WorkmenCompensationInsurance") {
        return new WorkmenCompensationInsuranceProduct()
    } else if (productName === "TravelInsurance") {
        return new TravelInsuranceProduct()
    } else if (productName === "FireInsurance") {
        return new FireInsuranceProduct()
    } else if (productName === "PublicLiabilityInsurance") {
        return new PublicLiabilityInsuranceProduct()
    }

    return new NullInsuranceProduct()
}