import create, { State } from "zustand"
import { db } from "../firebase"

interface AppState extends State {
    loadingInsuranceRequests: boolean
    insuranceRequests: Record<string, any>
    addInsuranceRequest: (id: string, request: any) => void
    fetchInsuranceRequest: (userId: string, id: string) => void
    fetchInsuranceRequests: (userId: string) => void
    partnerQuoteData: any
    partnerQuoteDataLoading: boolean
    partnerQuoteDataLoadError: boolean
    partnerLoadQuoteData: (id: string) => Promise<void>
}

export const useStore = create<AppState>(
    (set): AppState => ({
        loadingInsuranceRequests: false,
        insuranceRequests: {},
        addInsuranceRequest: (id: string, requestData: any) => {
            set(state => ({
                insuranceRequests: {
                    ...state.insuranceRequests,
                    [id]: { ...requestData }
                }
            }))
        },
        fetchInsuranceRequest: async (userId: string, id: string) => {
            try {
                const docPath = `/users/${userId}/insurance_requests/${id}`
                const docSnap = await db.doc(docPath).get()
                const docData = docSnap.data()

                if (!docData) throw new Error("Invalid insurance request data")

                set(state => ({
                    insuranceRequests: {
                        ...state.insuranceRequests,
                        [id]: { ...docData }
                    }
                }))
            } catch (error) {
                console.log(error);
            }
        },
        fetchInsuranceRequests: async (userId: string) => {
            try {
                set({ loadingInsuranceRequests: true })
                const docsPath = `/users/${userId}/insurance_requests`
                const docsSnap = await db.collection(docsPath).get()
                const mapData: Record<string, any> = {}
                docsSnap.forEach(doc => {
                    mapData[doc.id] = { ...doc.data() }
                })

                set({ insuranceRequests: mapData, loadingInsuranceRequests: false })
            } catch (error) {
                console.log(error);
            }
        },
        partnerQuoteData: undefined,
        partnerQuoteDataLoading: false,
        partnerQuoteDataLoadError: false,
        partnerLoadQuoteData: async (id: string) => {
            try {
                set(() => ({
                    partnerQuoteData: undefined,
                    partnerQuoteDataLoading: true,
                    partnerQuoteDataLoadError: false
                }))

                const docPath = `/partner_quote_requests/${id}`
                const docSnap = await db.doc(docPath).get()
                const docData = docSnap.data()

                if (!docData) {
                    throw new Error("Invalid request id")
                }

                set(() => ({
                    partnerQuoteData: { ...docData },
                    partnerQuoteDataLoading: false,
                    partnerQuoteDataLoadError: false
                }))
            } catch (error) {
                set(() => ({
                    partnerQuoteData: undefined,
                    partnerQuoteDataLoading: false,
                    partnerQuoteDataLoadError: true
                }))
                console.log(error);
            }
        },
    })
)