import { VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Route, Switch, useLocation } from 'wouter'
import { useSession } from '../../auth'
import { getInsuranceProduct } from '../../products'
import { RequestSummaryInfo } from '../../products/common'
import { useStore } from '../../store'
import { RequestCard } from './components/RequestCard'
import NewRequest from "./NewRequest"
import ViewRequest from './ViewRequest'

export default function Index() {
  const user = useSession()
  const userId = user?.uid
  // eslint-disable-next-line
  const [_, setLocation] = useLocation();
  const {
    insuranceRequests: motorInsuranceRequests,
    fetchInsuranceRequests: fetchMotorInsuranceRequests } = useStore()

  useEffect(() => {
    if(!userId) return

    fetchMotorInsuranceRequests(userId)
    return () => { }
  }, [userId, fetchMotorInsuranceRequests])

  const getSummaryInfo = (requestData: any): RequestSummaryInfo => {
    return getInsuranceProduct(requestData.type).requestSummaryInfo(requestData)
  }

  if (!userId) {
    setLocation("/login")
    return null
  }

  return (
    <div>
      <Switch>
        <Route path="/requests/n/:type" component={NewRequest} />
        <Route path="/requests/:id" component={ViewRequest} />
        <Route>
          <VStack spacing={5}>
            {
              Object.keys(motorInsuranceRequests).map(id => {
                return (
                  <RequestCard
                    key={id}
                    summaryInfo={getSummaryInfo(motorInsuranceRequests[id])}
                    onContinue={() => { setLocation(`/requests/${id}`) }}
                    onCancel={() => { console.log(`Cancelled ${id}`) }}
                  />
                )
              })
            }
          </VStack>
        </Route>
      </Switch>
    </div>
  )
}
