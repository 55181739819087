import { createRequestSummaryInfo, RequestSummaryInfo } from "../common"
import { RequestModel } from "./types"

export const requestSummaryInfo = (data: RequestModel): RequestSummaryInfo => {
    const summary: RequestSummaryInfo = createRequestSummaryInfo(data)

    summary.fields.push({ label: "Group Name", value: data.proposer.groupName })
    summary.fields.push({ label: "Members", value: data.proposer.noOfMembers })
    summary.fields.push({ label: "Average Age", value: data.proposer.averageAge })

    return summary
}
