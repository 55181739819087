import { AlertStatus } from '@chakra-ui/react'
import {
    InsuranceRequestStage,
    RequestStageDecorator,
    RequestSummaryInfo
} from '../types'

export const decorateStage = (stage: InsuranceRequestStage): RequestStageDecorator => {
    return new DefaultRequestStageDecorator(stage)
}

export const createRequestSummaryInfo = (data: any): RequestSummaryInfo => {
    return {
        title: data.type || "Invalid",
        fields: [],
        stage: data.stage || "Invalid",
        continue: data.stage === "In Progress" || data.stage === "Information Needed"
    }
}

class DefaultRequestStageDecorator implements RequestStageDecorator {
    stage: InsuranceRequestStage

    readonly label: string
    readonly isEditable: boolean
    readonly canContinueRequest: boolean
    readonly nextStage: string
    readonly alertStatus: AlertStatus
    readonly showQuoteTab: boolean
    readonly showPaymentTab: boolean
    readonly showPolicyTab: boolean

    private nextStageMap: Record<InsuranceRequestStage, string> = {
        "In Progress": "Please complete your application",
        "Quote Requested": "Please wait for quote to be generated",
        "Quote Provided": "Please accept quote and Submit OR Withdraw your request",
        "Quote Accepted": "Please proceed with payment",
        "Payment Made": "You made payment. Please wait for confirmation",
        "Payment Confirmed": "Payment confirmed. Your request will be processed soon",
        "Information Needed": "More information is needed. Please complete your application",
        "Processing Policy": "Your request is being proccessed. Please wait patiently...",
        "Policy Processed": "Your policy has been processed. Please acknowledge",
        "Request Withdrawn": "Your application was withdrawn by you. Please open a new application if you are still interested",
        "Request Declined": "We cannot accept your request at this time",
        "Request Completed": "Your request was completed successfully",
    }

    private alertStatusMap: Record<InsuranceRequestStage, AlertStatus> = {
        "In Progress": "warning",
        "Quote Requested": "info",
        "Quote Provided": "info",
        "Quote Accepted": "info",
        "Payment Made": "info",
        "Payment Confirmed": "success",
        "Information Needed": "warning",
        "Processing Policy": "info",
        "Policy Processed": "success",
        "Request Withdrawn": "error",
        "Request Declined": "error",
        "Request Completed": "success",
    }

    private stageIndexMap: Record<InsuranceRequestStage, number> = {
        "In Progress": 0,
        "Quote Requested": 1,
        "Quote Provided": 2,
        "Quote Accepted": 3,
        "Payment Made": 4,
        "Payment Confirmed": 5,
        "Information Needed": 6,
        "Processing Policy": 7,
        "Policy Processed": 8,
        "Request Completed": 11,
        "Request Withdrawn": -1,
        "Request Declined": -2,
    }

    constructor(stage: InsuranceRequestStage) {
        this.stage = stage
        this.label = stage

        this.isEditable = (
            this.stage === "In Progress" ||
            this.stage === "Information Needed"
        )

        this.canContinueRequest = (
            this.stage === "In Progress" ||
            this.stage === "Information Needed"
        )

        this.nextStage = this.nextStageMap[stage] || "Loading..."
        this.alertStatus = this.alertStatusMap[stage] || "info"

        this.showQuoteTab = this.stageIndexMap[stage] > 0 // not in pending
        this.showPaymentTab = this.stageIndexMap[stage] > 3 // from quote accepted
        this.showPolicyTab = this.stageIndexMap[stage] >= 8 // policy processed
    }
}