import {
    addressUIField,
    dateOfBirthUIField,
    emailUIField,
    nameUIField,
    numberUIField,
    selectUIField,
    textUIField,
    UIConfigField,
} from "../common";

export const allFields: Record<string, UIConfigField> = {
    "proposer_groupName": {
        ...textUIField,
        name: "groupName",
        label: "Company/Group Name",
    },
    "proposer_address": {
        ...addressUIField,
    },
    "proposer_noOfMembers": {
        ...numberUIField,
        name: "noOfMembers",
        label: "No. of Employees/Members",
    },
    "proposer_averageAge": {
        ...numberUIField,
        name: "averageAge",
        label: "Average Age of Employees/Members",
    },
    "proposer_totalAnnualSalary": {
        ...numberUIField,
        name: "totalAnnualSalary",
        label: "Total Annual Salary of Employees/Members",
    },
    "proposer_email": {
        ...emailUIField,
        label: "Company/Group email",
    },
    "additionalBenefits_retirementBenefitSnrStaff": {
        ...numberUIField,
        name: "retirementBenefitSnrStaff",
        label: "Retirement Benefit - Senior Staff",
        required: false,
    },
    "additionalBenefits_retirementBenefitJnrStaff": {
        ...numberUIField,
        name: "retirementBenefitJnrStaff",
        label: "Retirement Benefit - Junior Staff",
        required: false,
    },
    "additionalBenefits_spousalBenefit": {
        ...numberUIField,
        name: "spousalBenefit",
        label: "Spousal Benefit",
        required: false,
    },
    "additionalBenefits_coffinBenefit": {
        ...numberUIField,
        name: "coffinBenefit",
        label: "Coffin Benefit",
        required: false,
    },
    "member_memberId": {
        ...textUIField,
        name: "memberId",
        label: "Employee/Member ID",
    },
    "member_name": {
        ...nameUIField,
    },
    "member_dateOfBirth": {
        ...dateOfBirthUIField,
    },
    "member_rank": {
        ...selectUIField,
        name: "rank",
        label: "Rank",
        selectData: [
            { key: "Senior Staff", value: "Senior Staff" },
            { key: "Junior Staff", value: "Junior Staff" },
        ]
    },
    "member_annualSalary": {
        ...numberUIField,
        name: "annualSalary",
        label: "Annual Salary",
    },
}