import { Fragment } from 'react'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react'
import { RequestSummaryInfo } from '../../../products/common'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { FaTrashAlt } from 'react-icons/fa'

interface Props {
  summaryInfo: RequestSummaryInfo,
  onContinue: () => void,
  onCancel: () => void,
}

const colorSchemes: Record<string, string> = {
    "In Progress": "yellow",
    "Quote Requested": "blue",
    "Request Declined": "red",
    "Quote Provided": "blue",
    "Quote Accepted": "blue",
    "Request Withdrawn": "gray",
    "Payment Made": "blue",
    "Payment Confirmed": "blue",
    "Information Needed": "blue",
    "Processing Policy": "green",
    "Policy Processed": "green",
    "Request Completed": "green",
}

export const RequestCard = ({
  summaryInfo,
  onContinue,
  onCancel,
}: Props) => {
  const colorScheme = colorSchemes[summaryInfo.stage]

  return (
    <Box
      shadow="sm"
      width="full"
      borderWidth="1px"
      borderRadius="md"
      borderLeftWidth={5}
      borderLeftColor={`${colorScheme}.300`}
    >
      <Flex width="full">
        <Stack flex="1" px={2} py={1} spacing={1} width="full">
          <Heading fontSize="md" isTruncated my={2}>
            {summaryInfo.title} &nbsp;&nbsp;
            <Badge colorScheme={colorScheme}>{summaryInfo.stage}</Badge>
          </Heading>
          <Divider />
          <Text>
            {
              summaryInfo.fields.map((data, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  <b>{data.label}:</b> {data.value}
                </Fragment>
              ))
            }
          </Text>
          <HStack spacing={2}>
            <Spacer />
            <Button size="sm" colorScheme="blue" variant="outline" onClick={onContinue}>
              {summaryInfo.continue ? "Continue" : "View"}
            </Button>
            <Menu placement="bottom-end">
                <MenuButton
                    as={IconButton}
                    icon={<ChevronDownIcon />}
                    size="sm"
                    transition="all 0.2s"
                    borderRadius="md"
                    borderWidth="1px"
                    _hover={{ bg: "gray.100" }}
                    _focus={{ outline: 0, boxShadow: "outline" }}
                />
                <MenuList>
                    <MenuItem onClick={onCancel}>
                        <FaTrashAlt />
                        <span style={{ marginLeft: "10px" }}>Cancel</span>
                    </MenuItem>
                </MenuList>
            </Menu>
          </HStack>
        </Stack>
      </Flex>
    </Box>
  )
}
