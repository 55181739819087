import React from 'react'
import { Box, Center, CircularProgress } from '@chakra-ui/react'
import { useWindowDimensions } from '../hooks'

export const LoadingFull = () => {
  const { height } = useWindowDimensions();
  return (
    <Box mx={0} my={0} width="full" mt={height/2 - 20}>
      <Center>
        <CircularProgress isIndeterminate />
      </Center>
    </Box>
  )
}
