import React from 'react'
import { Button } from '@chakra-ui/react'
import { FaThumbsDown } from 'react-icons/fa'

interface Props {
    isSubmitting: boolean
    isWithdrawing: boolean
    onClick: () => void
}

export const WithdrawButton = ({
    isSubmitting,
    isWithdrawing,
    onClick
}: Props) => {
    return (
        <Button
            leftIcon={<FaThumbsDown />}
            colorScheme="red"
            size="lg"
            isLoading={isWithdrawing}
            loadingText="Withdrawing your request"
            hidden={isSubmitting}
            onClick={onClick}
        >
            Withdraw
        </Button>
    )
}
