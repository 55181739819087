import { AlertStatus, UseToastOptions } from "@chakra-ui/react";

export enum UIFieldType {
    text = "text",
    number = "number",
    email = "email",
    phone = "phone",
    textarea = "textarea",
    date = "date",
    select = "select",
}

export enum DBDataType {
    string = "string",
    number = "number",
    boolean = "boolean",
    map = "map",
    array = "array",
    timestamp = "timestamp",
    geopoint = "geopoint",
}

export type UIConfigFieldDataValidator = (fieldValue: any, sectionData: any, recordData: any) => boolean

export interface UIConfigField {
    name: string
    label: string
    required: boolean | ((record: any) => boolean)
    fieldType: UIFieldType
    dataType: DBDataType
    dataKey?: string
    selectData?: { key: string, value: string }[]
    placeholder?: string
    helperText?: string
    hidden?: boolean | ((record: any) => boolean)
    validator?: UIConfigFieldDataValidator
}

export type UIConfigSectionDataValidator = (sectionData: any, recordData: any) => boolean

export interface UIConfigSectionSingle {
    name: string
    dataKey: string
    title: string | ((record: any) => string)
    visible: boolean | ((record: any) => boolean)
    editable: boolean | ((record: any) => boolean)
    fields: UIConfigField[]
    validator?: UIConfigSectionDataValidator
}

export interface UIConfigSectionCollection extends UIConfigSectionSingle {
    headerText: (data: any) => string
    collapsedHtml: (data: any) => string
    keyPropertyName: string
}

export type UIConfigSection = UIConfigSectionSingle | UIConfigSectionCollection

export type UIConfigDataValidator = (recordData: any) => boolean
export interface UIConfig {
    title: string
    sections: UIConfigSection[]
    validator?: UIConfigDataValidator
}

export interface InsuranceProductActionApi {
    runAction(actionName: string, requestData: any): Promise<any>
    createRequest(data: any): Promise<string>
    updateRequest(requestId: string, data: any): Promise<any>
    updateRequestItem(requestId: string, itemType: string, itemData: any): Promise<any>
    deleteRequestItem(requestId: string, itemType: string, itemData: any): Promise<any>
    submitRequest(data: string): Promise<any>
    withdrawRequest(data: string): Promise<any>
}

export interface InsuranceProductToastOptions {
    createSuccess(): UseToastOptions
    createError(error: any): UseToastOptions
    updateSuccess(): UseToastOptions
    updateError(error: any): UseToastOptions
    itemUpdateSuccess(itemType: string): UseToastOptions
    itemUpdateError(itemType: string, error: any): UseToastOptions
    itemDeleteSuccess(itemType: string): UseToastOptions
    itemDeleteError(itemType: string, error: any): UseToastOptions
    submitSuccess(): UseToastOptions
    submitError(error: any): UseToastOptions
    invalidRequestWarning(data: any): UseToastOptions
    withdrawSuccess(): UseToastOptions
    withdrawError(error: any): UseToastOptions
}

export interface RequestSummaryInfo {
    title: string
    stage: string
    fields: { label: string, value: any }[]
    continue: boolean
}

export type InsuranceRequestStage = "In Progress" |
    "Quote Requested" |
    "Request Declined" |
    "Quote Provided" |
    "Quote Accepted" |
    "Request Withdrawn" |
    "Payment Made" |
    "Payment Confirmed" |
    "Information Needed" |
    "Processing Policy" |
    "Policy Processed" |
    "Request Completed"

export interface RequestStageDecorator {
    label: string
    isEditable: boolean
    canContinueRequest: boolean
    nextStage: string
    alertStatus: AlertStatus
    showQuoteTab: boolean
    showPaymentTab: boolean
    showPolicyTab: boolean
}

export type InsuranceProductType =
    "AutoInsurance" |
    "GroupComprehensiveLifeInsurance" |
    "GroupPersonalAccidentInsurance" |
    "WorkmenCompensationInsurance" |
    "FireInsurance" |
    "PublicLiabilityInsurance" |
    "TravelInsurance"

export interface InsuranceRequestModel {
    id: string
    type: InsuranceProductType
    currency: string
    stage: InsuranceRequestStage
    updateCount: number
    createdAt: Date
    updatedAt?: Date
}

export interface InsuranceProduct {
    name: string
    version: string
    enabled: boolean
    actionApi: InsuranceProductActionApi
    toastOptions: InsuranceProductToastOptions
    initialForm: () => UIConfig
    preQuoteView: (stage: InsuranceRequestStage) => UIConfig
    requestSummaryInfo(data: any): RequestSummaryInfo
    // prePaymentView: (stage: string) => UIConfig
    // prePolicyView: (stage: string) => UIConfig
    // postPolicyView: (stage: string) => UIConfig
}
