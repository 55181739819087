import { useState } from 'react'
import {
    Box,
    Divider,
    Flex,
    Heading,
    Spacer,
    Stack
} from '@chakra-ui/react'
import { SimpleFormFieldProps, SimpleFormViewer } from '../../components/SimpleForm'
import { CardActionButtons } from './CardActionButtons'
import { UIConfigSectionCollection } from '../common'

interface Props {
    uiConfigSection: UIConfigSectionCollection
    fields: SimpleFormFieldProps[]
    data: any
    isEditable?: boolean
    isExpanded?: boolean
    isValid?: boolean
    onEdit: () => void
    onDelete: () => void
}

export const UIConfigSectionCollectionCard = ({
    uiConfigSection,
    fields,
    data,
    isEditable = false,
    isExpanded = false,
    isValid = false,
    onEdit,
    onDelete,
}: Props) => {
    const [expanded, setExpanded] = useState(!isValid || isExpanded)

    return (
        <Box
            shadow="sm"
            width="full"
            borderWidth="1px"
            borderRadius="md"
            borderLeftWidth={5}
            borderLeftColor={isValid ? "green.300" : "yellow.300"}
        >
            <Flex width="full">
                <Stack flex="1" px={2} py={1} spacing={1} width="full">
                    <Flex width="full">
                        <Heading fontSize="md" isTruncated my={2}>
                            {uiConfigSection.headerText(data)}
                        </Heading>
                        <Spacer />
                        <CardActionButtons
                            isExpanded={expanded}
                            isEditable={isEditable}
                            onExpand={() => setExpanded(true)}
                            onCollapse={() => setExpanded(false)}
                            onEdit={onEdit}
                            onDelete={onDelete}
                        />
                    </Flex>
                    <Divider />
                    {
                        !expanded && <div
                            dangerouslySetInnerHTML={{ __html: uiConfigSection.collapsedHtml(data) }}
                        />
                    }
                    {
                        expanded && <SimpleFormViewer
                            fields={fields}
                            values={data}
                            isCondensed
                        />
                    }
                </Stack>
            </Flex>
        </Box>
    )
}
