import { missingTextHtml, uiConfigFieldsGetter, UIConfigSection } from "../common"
import { allFields } from "./uiConfigFields"

export const getFields = uiConfigFieldsGetter(allFields)

export const initialYourInfoSection: UIConfigSection = {
    title: "YOUR INFORMATION",
    name: "YourInfo",
    dataKey: "yourInfo",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_name",
            "proposer_address",
            "proposer_phoneNo",
            "proposer_email",
            "proposer_idType",
            "proposer_idNumber",
            "proposer_dateOfBirth"
        ])
    ]
}

export const initialVehicleInfoSection: UIConfigSection = {
    title: "1ST VEHICLE INFORMATION",
    name: "VehicleInfo",
    dataKey: "vehicleInfo",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "vehicle_registrationNo",
            "vehicle_chassisNo",
            "vehicle_preferredCover"
        ])
    ]
}

export const proposerSection: UIConfigSection = {
    title: "PROPOSER INFORMATION",
    name: "Proposer",
    dataKey: "proposer",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_name",
            "proposer_address",
            "proposer_phoneNo",
            "proposer_email",
            "proposer_idType",
            "proposer_idNumber",
            "proposer_dateOfBirth"
        ])
    ]
}

export const vehiclesSection: UIConfigSection = {
    title: "VEHICLES",
    name: "Vehicle",
    dataKey: "vehicles",
    visible: true,
    editable: true,
    headerText: (data: any) => data.registrationNo || "Not Registered",
    collapsedHtml: (data: any) => `
        <b>Name:</b> ${data.make || missingTextHtml("Make missing")} / ${data.model || missingTextHtml("Model missing")}
        <br/>
        <b>Chassis No:</b> ${data.chassisNo || missingTextHtml("Chassis No. missing")}
    `,
    keyPropertyName: "chassisNo",
    fields: [
        ...getFields([
            "vehicle_make",
            "vehicle_model",
            "vehicle_registrationNo",
            "vehicle_vehicleYear",
            "vehicle_color",
            "vehicle_bodyStyle",
            "vehicle_cubicCapacity",
            "vehicle_seatingCapacity",
            "vehicle_value",
            "vehicle_chassisNo",
            "vehicle_preferredCover"
        ])
    ]
}

export const driversSection: UIConfigSection = {
    title: "DRIVERS",
    name: "Driver",
    dataKey: "drivers",
    visible: true,
    editable: true,
    headerText: (data: any) => data.name || "No Name",
    collapsedHtml: (data: any) => `
        <b>Name:</b> ${data.name || missingTextHtml("Name missing")}
        <br/>
        <b>Years of License:</b> ${data.yearsOfLicense || missingTextHtml("Years of License missing")}
    `,
    keyPropertyName: "driverId",
    fields: [
        ...getFields([
            "driver_driverId",
            "driver_name",
            "driver_age",
            "driver_yearsOfLicense",
            "driver_offencesAndAccidentsHistory"
        ])
    ]
}
