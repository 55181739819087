import {
    addressUIField,
    emailUIField,
    nameUIField,
    numberUIField,
    phoneNoUIField,
    phoneUIField,
    selectUIField,
    textUIField,
    UIConfigField,
} from "../common";

export const allFields: Record<string, UIConfigField> = {
    "proposer_name": {
        ...nameUIField,
    },
    "proposer_address": {
        ...addressUIField,
    },
    "proposer_phoneNo": {
        ...phoneNoUIField,
    },
    "proposer_faxNo": {
        ...phoneUIField,
        name: "faxNo",
        label: "Fax No",
        required: false
    },
    "proposer_noOfPrivateBuildings": {
        ...numberUIField,
        name: "noOfPrivateBuildings",
        label: "No of Private Buildings",
    },
    "proposer_noOfBusinessBuildings": {
        ...numberUIField,
        name: "noOfBusinessBuildings",
        label: "No of Business Buildings",
    },
    "proposer_occupationOrBusiness": {
        ...textUIField,
        name: "occupationOrBusiness",
        label: "Occupation Or Business",
    },
    "proposer_nationality": {
        ...textUIField,
        name: "nationality",
        label: "Nationality",
    },
    "proposer_email": {
        ...emailUIField,
    },
    "building_buildingId": {
        ...textUIField,
        name: "buildingId",
        label: "Building Id",
        hidden: true,
    },
    "building_location": {
        ...textUIField,
        name: "location",
        label: "Location",
    },
    "building_builtWith": {
        ...textUIField,
        name: "builtWith",
        label: "Built With",
    },
    "building_roofedWith": {
        ...textUIField,
        name: "roofedWith",
        label: "Roofed With",
    },
    "building_sumInsured": {
        ...numberUIField,
        name: "sumInsured",
        label: "Sum Insured",
    },
    "building_premiseType": {
        ...selectUIField,
        name: "premiseType",
        label: "Premise Type",
        selectData: [
            { key: "Private Premise", value: "Private Premise" },
            { key: "Business Premise", value: "Business Premise" },
        ]
    },
}