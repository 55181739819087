import { missingTextHtml, uiConfigFieldsGetter, UIConfigSection } from "../common"
import { allFields } from "./uiConfigFields"

export const getFields = uiConfigFieldsGetter(allFields)

export const initialYourInfoSection: UIConfigSection = {
    title: "YOUR INFORMATION",
    name: "YourInfo",
    dataKey: "yourInfo",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "person_surname",
            "person_othernames",
            "person_dateOfBirth",
            "person_gender",
            "person_nationality",
            "person_countryOfResidence",
            "person_passportNo",
            "proposer_address",
            "proposer_email",
            "proposer_mobileNo",
        ])
    ]
}

export const initialTravelInfoSection: UIConfigSection = {
    title: "TRAVEL INFORMATION",
    name: "TravelInfo",
    dataKey: "travelInfo",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_destination",
            "proposer_dateOfDeparture",
            "proposer_dateOfReturn",
            "proposer_purposeOfTravel",
            "proposer_noOfAdults",
            "proposer_noOfChildren"
        ])
    ]
}

export const proposerSection: UIConfigSection = {
    title: "TRAVEL INFORMATION",
    name: "Proposer",
    dataKey: "proposer",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_address",
            "proposer_email",
            "proposer_mobileNo",
            "proposer_destination",
            "proposer_dateOfDeparture",
            "proposer_dateOfReturn",
            "proposer_purposeOfTravel",
            "proposer_noOfAdults",
            "proposer_noOfChildren"
        ])
    ]
}

export const productOptionsSection: UIConfigSection = {
    title: "TRAVEL OPTIONS",
    name: "ProductOptions",
    dataKey: "productOptions",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "productOptions_europeSchengen",
            "productOptions_traveller",
            "productOptions_family",
            "productOptions_pearl",
            "productOptions_economy",
        ])
    ]
}

export const adultMembersSection: UIConfigSection = {
    title: "ADULTS",
    name: "Adult",
    dataKey: "adults",
    visible: true,
    editable: true,
    headerText: (data: any) => `Passport No: ${data.passportNo || "Unknown"}`,
    collapsedHtml: (data: any) => `
        <b>Name:</b> ${data.surname || missingTextHtml("Surname missing")} ${data.othernames || missingTextHtml("Othernames missing")}
        <br/>
        <b>Date of Birth:</b> ${data.dateOfBirth}
    `,
    keyPropertyName: "passportNo",
    fields: [
        ...getFields([
            "person_surname",
            "person_othernames",
            "person_dateOfBirth",
            "person_gender",
            "person_nationality",
            "person_countryOfResidence",
            "person_passportNo"
        ])
    ]
}

export const childMembersSection: UIConfigSection = {
    title: "CHILDREN",
    name: "Child",
    dataKey: "children",
    visible: true,
    editable: true,
    headerText: (data: any) => `Passport No: ${data.passportNo || "Unknown"}`,
    collapsedHtml: (data: any) => `
        <b>Name:</b> ${data.surname || missingTextHtml("Surname missing")} ${data.othernames || missingTextHtml("Othernames missing")}
        <br/><b>Date of Birth:</b> ${data.dateOfBirth}
    `,
    keyPropertyName: "passportNo",
    fields: [
        ...getFields([
            "person_surname",
            "person_othernames",
            "person_dateOfBirth",
            "person_gender",
            "person_nationality",
            "person_countryOfResidence",
            "person_passportNo"
        ])
    ]
}
