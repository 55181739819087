import {
    addressUIField,
    dateOfBirthUIField,
    emailUIField,
    nameUIField,
    numberUIField,
    phoneNoUIField,
    selectUIField,
    textareaUIField,
    textUIField,
    UIConfigField,
} from "../common";

export const allFields: Record<string, UIConfigField> = {
    "proposer_name": {
        ...nameUIField,
        placeholder: "Your name",
    },
    "proposer_address": {
        ...addressUIField,
    },
    "proposer_phoneNo": {
        ...phoneNoUIField,
    },
    "proposer_email": {
        ...emailUIField,
    },
    "proposer_idType": {
        ...selectUIField,
        name: "idType",
        label: "ID Type",
        selectData: [
            { key: "Voter ID", value: "Voter ID" },
            { key: "Passport", value: "Passport" },
        ]
    },
    "proposer_idNumber": {
        ...textUIField,
        name: "idNumber",
        label: "ID Number",
    },
    "proposer_dateOfBirth": {
        ...dateOfBirthUIField,
    },
    "vehicle_make": {
        ...textUIField,
        name: "make",
        label: "Make",
    },
    "vehicle_model": {
        ...textUIField,
        name: "model",
        label: "Model",
    },
    "vehicle_registrationNo": {
        ...textUIField,
        name: "registrationNo",
        label: "Registration No",
        required: false,
    },
    "vehicle_vehicleYear": {
        ...numberUIField,
        name: "vehicleYear",
        label: "Vehicle Year",
    },
    "vehicle_color": {
        ...textUIField,
        name: "color",
        label: "Color",
    },
    "vehicle_bodyStyle": {
        ...selectUIField,
        name: "bodyStyle",
        label: "Body Style",
        selectData: [
            { key: "Sedan / Saloon", value: "Sedan / Saloon" },
            { key: "Coupé", value: "Coupé" },
            { key: "Station Wagon", value: "Station Wagon" },
            { key: "Minivan", value: "Minivan" },
            { key: "Hatchback", value: "Hatchback" },
            { key: "Convertible", value: "Convertible" },
            { key: "Pickup Truck", value: "Pickup Truck" },
            { key: "Sport-Utility Vehicle (SUV)", value: "Sport-Utility Vehicle (SUV)" },
            { key: "Others", value: "Others" },
        ]
    },
    "vehicle_cubicCapacity": {
        ...numberUIField,
        name: "cubicCapacity",
        label: "Cubic Capacity",
    },
    "vehicle_seatingCapacity": {
        ...numberUIField,
        name: "seatingCapacity",
        label: "Seating Capacity",
    },
    "vehicle_value": {
        ...numberUIField,
        name: "value",
        label: "Value",
    },
    "vehicle_chassisNo": {
        ...textUIField,
        name: "chassisNo",
        label: "Chassis No",
    },
    "vehicle_preferredCover": {
        ...selectUIField,
        name: "preferredCover",
        label: "Preferred Cover",
        selectData: [
            { key: "Comprehensive - 100%", value: "Comprehensive - 100%" },
            { key: "Comprehensive - 90%", value: "Comprehensive - 90%" },
            { key: "Third Party", value: "Third Party" },
            { key: "Third Party Fire & Theft", value: "Third Party Fire & Theft" },
        ]
    },
    "driver_driverId": {
        ...textUIField,
        name: "driverId",
        label: "Driver Id",
        required: false,
        hidden: true,
    },
    "driver_name": {
        ...nameUIField,
    },
    "driver_age": {
        ...numberUIField,
        name: "age",
        label: "Age",
    },
    "driver_yearsOfLicense": {
        ...numberUIField,
        name: "yearsOfLicense",
        label: "Years of License",
    },
    "driver_offencesAndAccidentsHistory": {
        ...textareaUIField,
        name: "offencesAndAccidentsHistory",
        label: "Driving History",
        required: false,
    },
}