import { UseToastOptions } from "@chakra-ui/react"
import { InsuranceProductToastOptions } from "./types"

const toastExtra: { duration: number, isClosable: boolean, } = { duration: 9000, isClosable: true }

export const createToastOptions = (): InsuranceProductToastOptions => {
    const toastOptions: InsuranceProductToastOptions = {
        createSuccess(): UseToastOptions {
            return {
                ...toastExtra,
                status: "success",
                title: "Request Initiated",
                description: "Please wait for quotes to be generated"
            }
        },
        createError(error: any): UseToastOptions {
            return {
                ...toastExtra,
                status: "error",
                title: "Request Failed",
                description: error?.toString()
            }
        },
        updateSuccess(): UseToastOptions {
            return { description: "Not implemented" }
        },
        updateError(error: any): UseToastOptions {
            return { description: "Not implemented" }
        },
        itemUpdateSuccess(itemType: string): UseToastOptions {
            return {
                ...toastExtra,
                status: "success",
                title: `${itemType} Information Updated`,
                description: `Successfully updated ${itemType} information`
            }
        },
        itemUpdateError(itemType: string, error: any): UseToastOptions {
            return {
                ...toastExtra,
                status: "error",
                title: `${itemType} Update Error`,
                description: error?.toString()
            }
        },
        itemDeleteSuccess(itemType: string): UseToastOptions {
            return {
                ...toastExtra,
                status: "success",
                title: `${itemType} Information Deleted`,
                description: `Successfully updated ${itemType} information`
            }
        },
        itemDeleteError(itemType: string, error: any): UseToastOptions {
            return {
                ...toastExtra,
                status: "error",
                title: `${itemType} Delete Error`,
                description: error?.toString()
            }
        },
        submitSuccess(): UseToastOptions {
            return {
                ...toastExtra,
                status: "success",
                title: "Request Submitted",
                description: "Request successfully submitted. Processing has started and we will get back to you shortly"
            }
        },
        submitError(error: any): UseToastOptions {
            return {
                ...toastExtra,
                status: "error",
                title: "Request Submit Error",
                description: error?.toString()
            }
        },
        invalidRequestWarning(data: any): UseToastOptions {
            return {
                ...toastExtra,
                status: "warning",
                title: "Incomplete Request",
                description: "Please provide all required data, marked by red asterix (*)"
            }
        },
        withdrawSuccess(): UseToastOptions {
            return {
                ...toastExtra,
                status: "success",
                title: "Request Withdrawn",
                description: "Request successfully withdrawn and will finally be deleted in 24 hours"
            }
        },
        withdrawError(error: any): UseToastOptions {
            return {
                ...toastExtra,
                status: "error",
                title: "Request Withdrawal Error",
                description: error?.toString()
            }
        }
    }

    return toastOptions
}