import {
    createActionApi,
    createToastOptions,
    InsuranceProductBase,
    InsuranceRequestStage,
    RequestSummaryInfo
} from "../common"
import { RequestModel } from "./types"
import { requestSummaryInfo as requestSummaryInfoFn } from './utils'
import {
    additionalBenefitsSection,
    membersSection,
    proposerSection
} from "./uiConfigSections"

export class GroupComprehensiveLifeInsuranceProduct extends InsuranceProductBase {
    name = "GroupComprehensiveLifeInsurance"
    version = "v1"
    enabled = true
    actionApi = createActionApi("callableGroupComprehensiveLifeInsuranceRequestActionFn")
    toastOptions = createToastOptions()

    initialForm() {
        return {
            title: "Group Comprehensive Life Insurance",
            sections: [
                proposerSection,
                additionalBenefitsSection
            ]
        }
    }

    preQuoteView(_stage: InsuranceRequestStage) {
        return {
            title: "Group Comprehensive Life Insurance",
            sections: [
                proposerSection,
                additionalBenefitsSection,
                membersSection
            ]
        }
    }

    requestSummaryInfo(data: RequestModel): RequestSummaryInfo {
        return requestSummaryInfoFn(data)
    }
}