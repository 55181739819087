import { useContext } from 'react';
import userContext from './../contexts/userContext';
import { firebase } from '../firebase'

const provider = new firebase.auth.GoogleAuthProvider();

export const useSession = () => {
  const { user } = useContext(userContext);
  return user;
};

export const loginWithGoogle = async (): Promise<any> => {
  try {
    const result = await firebase
      .auth()
      .signInWithPopup(provider);

    return Promise.resolve(result.user);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const loginWithEmail = async (email: string, password: string): Promise<any> => {
  try {
    const result = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    
    return Promise.resolve(result.user);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createUserWithEmail = async (email: string, password: string): Promise<any> => {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    return Promise.resolve(result.user);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const signOut = () => firebase.auth().signOut();