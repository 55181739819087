import { Box, Image } from '@chakra-ui/react'

export const BrandingPane = () => {
  return (
    <Box height="100%">
      <Image
        src="https://images.squarespace-cdn.com/content/58c880d7893fc0f2350b0bbd/1533848222044-4N7NBZBN0CIGTPVE1CES/insurance+image.jpg"
        height="100%"
      />
    </Box>
  )
}
