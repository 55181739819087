import React from 'react'
import { Flex, Stack, Heading, Divider, Accordion } from '@chakra-ui/react'
import {
    UIConfig,
    uiConfigFieldsToEmptyValues,
    uiConfigFieldsToSimpleFormFieldProps,
    UIConfigSection
} from '../common'
import { SimpleAccordionItem, SimpleAccordionItemProps } from './SimpleAccordionItem'
import { SimpleFormButtons, SimpleFormContainer, SimpleFormlet } from '../../components/SimpleForm'

interface Props {
    uiConfig: UIConfig
    colorScheme?: string
    onSave: (data: any) => Promise<void>
    onCancel?: () => void
}

const getSectionHeader = (section: UIConfigSection, record: any): string => {
    if (typeof section.title === "function") {
        return section.title(record)
    }

    return section.title
}

export const UIConfigFormCard = ({
    uiConfig,
    colorScheme = "teal",
    onSave,
    onCancel = () => { },
}: Props) => {
    const initialValues = uiConfig.sections.map(section => {
        return uiConfigFieldsToEmptyValues(section.fields)
    }).reduce((acc, e) => ({...acc, ...e}), {})
    
    const accordionItemsProps: SimpleAccordionItemProps[] = uiConfig.sections.map(section => {
        const fields = uiConfigFieldsToSimpleFormFieldProps(section.fields, {})
        const props: SimpleAccordionItemProps = {
            header: getSectionHeader(section, {}),
            body: (
                <SimpleFormlet
                    fields={fields}
                />
            )
        }

        return props
    })

    return (
        <Flex width="full">
            <Stack flex="1" px={0} py={1} spacing={1} width="full">
                <Heading fontSize="lg" isTruncated my={2}>
                    {uiConfig.title} &nbsp;&nbsp;
                </Heading>
                <Divider
                    my={2}
                    shadow="sm"
                    width="full"
                    borderWidth="0px"
                    borderRadius="md"
                    borderTopWidth={5}
                    borderTopColor={`${colorScheme}.300`} />
                <SimpleFormContainer<any>
                    initialValues={initialValues}
                    onSubmit={onSave}
                >
                    <Accordion allowToggle allowMultiple defaultIndex={[0, 1, 2, 3, 4]} width="full">
                        {
                            accordionItemsProps.map(itemProps => {
                                return (
                                    <SimpleAccordionItem key={itemProps.header} {...itemProps} />
                                )
                            })
                        }
                    </Accordion>
                    <SimpleFormButtons onCancel={onCancel} />
                </SimpleFormContainer>
            </Stack>
        </Flex>
    )
}
