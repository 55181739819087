import {
    UIConfig,
    UIConfigDataValidator,
    UIConfigField,
    UIConfigFieldDataValidator,
    UIConfigSection,
    UIConfigSectionCollection,
    UIConfigSectionDataValidator
} from "./types"

export const createUIConfigFieldDataValidator = (
    uiConfigField: UIConfigField
): UIConfigFieldDataValidator => {
    if (uiConfigField.validator) return uiConfigField.validator

    return (value: any, _sectionData: any, _recordData: any): boolean => {
        if (uiConfigField.required && (value === undefined || value.toString() === "")) {
            return false
        }

        return true
    }
}

export const createUIConfigSectionDataValidator = (
    uiConfigSection: UIConfigSection
): UIConfigSectionDataValidator => {
    if (uiConfigSection.validator) return uiConfigSection.validator

    return (sectionData: any, recordData: any): boolean => {
        const allFieldsValid = uiConfigSection.fields.every(field => {
            const validator = field.validator || createUIConfigFieldDataValidator(field)
            const value = sectionData[field.dataKey || field.name]
    
            return validator(value, sectionData, recordData)
        })
    
        return allFieldsValid
    }
}

export const createUIConfigDataValidator = (
    uiConfig: UIConfig,
    isFlattenedData: boolean = false
): UIConfigDataValidator => {
    if (uiConfig.validator) return uiConfig.validator

    return (recordData: any): boolean => {
        const allSectionsValid = uiConfig.sections.every(section => {
            const validator = section.validator || createUIConfigSectionDataValidator(section)
            const sectionData = isFlattenedData ? recordData : recordData[section.dataKey] || {}

            if ((section as UIConfigSectionCollection).keyPropertyName) {
                return Object.values(sectionData).every(itemData => {
                    return validator(itemData, recordData)
                })
            } else {
                return validator(sectionData, recordData)
            }
        })

        return allSectionsValid
    }
}
