import { useEffect } from 'react'
import {
    Spacer,
    SimpleGrid,
    useDisclosure,
    useToast,
    VStack,
    Center,
    CircularProgress
} from '@chakra-ui/react'
import { Fab, Action } from 'react-tiny-fab'
import { useSession } from '../../auth'
import {
    FaCar,
    FaCarCrash,
    FaPlus,
    FaRocketchat,
    FaUserPlus
} from 'react-icons/fa'
import { useLocation } from 'wouter'
import { useStore } from '../../store'
import { SimpleActionCard } from '../../components'
import { functions } from '../../firebase'
import { SimpleDrawer } from '../../components/SimpleDrawer'
import { SimpleForm } from '../../components/SimpleForm'
import { inviteFields, inviteInitialValues } from './invites'
import { RequestCard } from '../requests/components/RequestCard'
import 'react-tiny-fab/dist/styles.css'
import { InviteRequestModel } from '../../types'
import { getInsuranceProduct } from '../../products'
import { InsuranceProductType, RequestSummaryInfo } from '../../products/common'
import { insuranceRequestCardsProps } from './utils'

export default function Index() {
    const toast = useToast()
    const user = useSession()
    const userId = user?.uid
    const {
        loadingInsuranceRequests,
        insuranceRequests,
        fetchInsuranceRequests } = useStore()
    // eslint-disable-next-line
    const [_, setLocation] = useLocation();
    const {
        isOpen: inviteIsOpen,
        onOpen: inviteOnOpen,
        onClose: inviteOnClose } = useDisclosure()
    const {
        isOpen: requestIsOpen,
        onOpen: requestOnOpen,
        onClose: requestOnClose } = useDisclosure()

    useEffect(() => {
        if (!userId) return

        fetchInsuranceRequests(userId)
        return () => { }
    }, [userId, fetchInsuranceRequests])

    const getSummaryInfo = (requestData: any): RequestSummaryInfo => {
        return getInsuranceProduct(requestData.type).requestSummaryInfo(requestData)
    }

    const requestInsurance = (type: InsuranceProductType) => setLocation(`/requests/n/${type}`)
    const fileAClaim = () => setLocation("/claims/new")
    const contactUs = () => setLocation("/contact-us")

    const onSendInvite = async (values: InviteRequestModel): Promise<void> => {
        try {
            const actionFn = functions.httpsCallable('callableCreateInviteFn')
            const result = await actionFn(values)

            if (result.data.success) {
                toast({
                    title: "Invite Sent",
                    description: "Thanks for inviting your friends, we really appreciate it",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
                inviteOnClose()
                return Promise.resolve()
            } else {
                toast({
                    title: "Invite Error",
                    description: result.data.error,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                return Promise.reject()
            }
        } catch (error) {
            console.log(error);
            return Promise.reject()
        }
    }

    if (!userId) {
        setLocation("/login")
        return null
    }

    return (
        <>
            <VStack maxWidth={1100} mx="auto" spacing={5}>
                {
                    Object.keys(insuranceRequests).map(id => {
                        return (
                            <RequestCard
                                key={id}
                                summaryInfo={getSummaryInfo(insuranceRequests[id])}
                                onContinue={() => { setLocation(`/requests/${id}`) }}
                                onCancel={() => { console.log(`Cancelled ${id}`) }}
                            />
                        )
                    })
                }
                {
                    loadingInsuranceRequests && (
                        <Center width="full" mb={10}>
                            <CircularProgress isIndeterminate size="100px" thickness="4px" />
                        </Center>
                    )
                }
            </VStack>
            <Spacer height={5} />
            <SimpleGrid maxWidth={1100} mx="auto" columns={{ base: 1, md: 2 }} spacing={5}>
                {
                    insuranceRequestCardsProps.map(props => {
                        return <SimpleActionCard
                            {...props}
                            key={props.header}
                            buttonText="Get Quote"
                            onAction={() => requestInsurance(props.type)}
                        />
                    })
                }
                <SimpleActionCard
                    icon={FaCarCrash}
                    header="Insurance Claims"
                    body="An insurance claim is a formal request by a policyholder to an insurance company for coverage or compensation for a covered loss or policy event. The insurance company validates the claim and, once approved, issues payment to the insured or an approved interested party on behalf of the insured."
                    buttonText="File A Claim"
                    accentColor="red.300"
                    buttonColor="red"
                    onAction={fileAClaim}
                />
            </SimpleGrid>
            <SimpleDrawer
                isOpen={inviteIsOpen}
                onClose={inviteOnClose}
                header="Invite A Friend"
            >
                <SimpleForm<InviteRequestModel>
                    fields={inviteFields}
                    initialValues={inviteInitialValues}
                    onSubmit={onSendInvite}
                    onCancel={inviteOnClose}
                    submitText="Send Invite"
                />
            </SimpleDrawer>
            <SimpleDrawer
                isOpen={requestIsOpen}
                onClose={requestOnClose}
                header="Request Insurance"
            >
                Coming soon
            </SimpleDrawer>
            <Fab
                mainButtonStyles={{ backgroundColor: '#0aa' }}
                icon={<FaPlus />}
                event="hover"
                alwaysShowTitle={true}
            >
                <Action
                    text="Request Insurance"
                    style={{ backgroundColor: '#63B3ED' }}
                    onClick={(e) => { requestOnOpen() }}
                >
                    <FaCar />
                </Action>
                <Action
                    text="File A Claim"
                    style={{ backgroundColor: '#FC8181' }}
                    onClick={(e) => { fileAClaim() }}
                >
                    <FaCarCrash />
                </Action>
                <Action
                    text="Contact Us"
                    style={{ backgroundColor: '#68D391' }}
                    onClick={(e) => { contactUs() }}
                >
                    <FaRocketchat />
                </Action>
                <Action
                    text="Invite A Friend"
                    style={{ backgroundColor: '#B794F4' }}
                    onClick={(e) => { inviteOnOpen() }}
                >
                    <FaUserPlus />
                </Action>
            </Fab>
        </>
    )
}
