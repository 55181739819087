import {
    addressUIField,
    dateOfBirthUIField,
    dateUIField,
    emailUIField,
    genderUIField,
    mobileNoUIField,
    numberUIField,
    othernamesUIField,
    surnameUIField,
    textUIField,
    UIConfigField,
    yesNoUIField,
} from "../common";

export const allFields: Record<string, UIConfigField> = {
    "proposer_address": {
        ...addressUIField,
    },
    "proposer_email": {
        ...emailUIField,
    },
    "proposer_mobileNo": {
        ...mobileNoUIField,
    },
    "proposer_destination": {
        ...textUIField,
        name: "destination",
        label: "Destination",
    },
    "proposer_dateOfDeparture": {
        ...dateUIField,
        name: "dateOfDeparture",
        label: "Date Of Departure",
    },
    "proposer_dateOfReturn": {
        ...dateUIField,
        name: "dateOfReturn",
        label: "Date Of Return",
    },
    "proposer_purposeOfTravel": {
        ...textUIField,
        name: "purposeOfTravel",
        label: "Purpose Of Travel",
    },
    "proposer_noOfAdults": {
        ...numberUIField,
        name: "noOfAdults",
        label: "Number Of Adults",
    },
    "proposer_noOfChildren": {
        ...numberUIField,
        name: "noOfChildren",
        label: "Number Of Children",
        required: false,
    },
    "productOptions_europeSchengen": {
        ...yesNoUIField,
        name: "europeSchengen",
        label: "Europe Schengen",
    },
    "productOptions_traveller": {
        ...yesNoUIField,
        name: "traveller",
        label: "Traveller?",
    },
    "productOptions_family": {
        ...yesNoUIField,
        name: "family",
        label: "Travelling with family?",
    },
    "productOptions_pearl": {
        ...yesNoUIField,
        name: "pearl",
        label: "Pearl?",
    },
    "productOptions_economy": {
        ...yesNoUIField,
        name: "economy",
        label: "Economy?",
    },
    "person_surname": {
        ...surnameUIField,
    },
    "person_othernames": {
        ...othernamesUIField,
    },
    "person_dateOfBirth": {
        ...dateOfBirthUIField,
    },
    "person_gender": {
        ...genderUIField,
    },
    "person_nationality": {
        ...textUIField,
        name: "nationality",
        label: "Nationality",
    },
    "person_countryOfResidence": {
        ...textUIField,
        name: "countryOfResidence",
        label: "Country Of Residence",
    },
    "person_passportNo": {
        ...textUIField,
        name: "passportNo",
        label: "Passport Number",
    },
}