import { missingTextHtml, uiConfigFieldsGetter, UIConfigSection } from "../common"
import { allFields } from "./uiConfigFields"

export const getFields = uiConfigFieldsGetter(allFields)

export const proposerSection: UIConfigSection = {
    title: "COMPANY/GROUP INFORMATION",
    name: "Proposer",
    dataKey: "proposer",
    visible: true,
    editable: true,
    fields: [
        ...getFields([
            "proposer_groupName",
            "proposer_address",
            "proposer_noOfMembers",
            "proposer_averageAge",
            "proposer_totalAnnualSalary",
            "proposer_email"
        ])
    ]
}

export const membersSection: UIConfigSection = {
    title: "MEMBERS",
    name: "Member",
    dataKey: "members",
    visible: true,
    editable: true,
    headerText: (data: any) => `# ${data.memberId || "Unknown"}`,
    collapsedHtml: (data: any) => `
        <b>Name:</b> ${data.name || missingTextHtml("Name missing")}
        <br/>
        <b>Date of Birth:</b> ${data.dateOfBirth || missingTextHtml("Date of Birth missing")}
    `,
    keyPropertyName: "memberId",
    fields: [
        ...getFields([
            "member_memberId",
            "member_name",
            "member_dateOfBirth",
            "member_annualSalary"
        ])
    ]
}
