import React from 'react'
import { Box, Image, useColorModeValue } from '@chakra-ui/react'
import logoWhite from './../logo-white.png'
import logoBlack from './../logo-black.png'

type Props = {
  height?: number
}

export const Logo = ({ height = 30 }: Props) => {
  const logo = useColorModeValue(logoBlack, logoWhite)

  return (
    <Box flexShrink={0}>
      <Image
        height={height}
        src={logo}
        alt="Logo"
      />
    </Box>
  )
}
