import { createRequestSummaryInfo, RequestSummaryInfo } from "../common"
import { RequestModel } from "./types"

export const requestSummaryInfo = (data: RequestModel): RequestSummaryInfo => {
    const summary: RequestSummaryInfo = createRequestSummaryInfo(data)

    // summary.fields.push({ label: "Name", value: data.proposer.name })
    summary.fields.push({ label: "Address", value: data.proposer.address })

    return summary
}
