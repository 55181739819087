import {
    addressUIField,
    nameUIField,
    numberUIField,
    phoneNoUIField,
    textUIField,
    UIConfigField,
} from "../common";

export const allFields: Record<string, UIConfigField> = {
    "proposer_name": {
        ...nameUIField,
    },
    "proposer_address": {
        ...addressUIField,
    },
    "proposer_phoneNo": {
        ...phoneNoUIField,
    },
    "proposer_businessOrTrade": {
        ...textUIField,
        name: "businessOrTrade",
        label: "Business/Trade (Full description)",
    },
    "property_propertyId": {
        ...textUIField,
        name: "propertyId",
        label: "Property ID",
        hidden: true
    },
    "property_address": {
        ...addressUIField,
    },
    "property_indemnityForOneAccident": {
        ...numberUIField,
        name: "indemnityForOneAccident",
        label: "Indemnity for any one accident"
    },
}