import { DBDataType, UIConfigField, UIConfigSection, UIConfigSectionCollection } from "../../products/common"

const fieldIsRequired = (field: UIConfigField): boolean => {
    return (typeof field.required === "function")
        ? field.required({})
        : field.required
}

const transformField = (field: UIConfigField): object => {
    const transformedData: Record<string, any> = {
        name: field.dataKey || field.name,
        label: field.label,
        required: fieldIsRequired(field),
        dataType: field.dataType.toString(),
    }

    if (field.selectData) {
        transformedData.selectData = field.selectData
    }
    
    if (field.hidden) {
        transformedData.hidden = true
    }

    return transformedData
}

const redactedFields = (fields: UIConfigField[]): string[] => {
    const sensitiveFields = [
        "name",
        "surname",
        "othernames",
        "address",
        "phoneNo",
        "faxNo",
        "email",
    ]

    return fields.map(e => e.name).filter(e => sensitiveFields.includes(e))
}

export const transformSection = (
    section: UIConfigSection,
    emptyRedactedFields: boolean = false
): object => {
    const transformedData: Record<string, any> = {
        title: section.title,
        dataKey: section.dataKey,
        redacted: emptyRedactedFields ? [] : redactedFields(section.fields),
        properties: section.fields.map(field => transformField(field))
    }

    if ((section as UIConfigSectionCollection).keyPropertyName) {
        transformedData.keyPropertyName = (section as UIConfigSectionCollection).keyPropertyName
    }

    return transformedData
}

const getFieldDefinitions = (fields: UIConfigField[]): string[] => {
    return fields.map(field => {
        const tokens: string[] = []
        tokens.push(`${field.dataKey || field.name}`)
        fieldIsRequired(field) ? tokens.push(": ") : tokens.push("?: ")
        tokens.push(field.dataType === DBDataType.timestamp ? "Date" : field.dataType)

        return tokens.join("")
    })
}

export const getInterfaceDefinition = (typeName: string, fields: UIConfigField[]): string => {
    const tokens: string[] = []

    tokens.push(`export interface ${typeName} {`)
    getFieldDefinitions(fields).forEach(fieldDef => tokens.push(`    ${fieldDef}`))
    tokens.push("}")

    return tokens.join("\n")
}