import { ReactNode } from "react"
import { Table, TableCaption, Tbody, Td, Th, Tr } from "@chakra-ui/react"
import { GroupedRequestDataPoint } from "./types"

export const groupedDataPointsToTable = (
    groupedDataPoints: GroupedRequestDataPoint[]
): ReactNode => {
    const trNodes: ReactNode[] = []
    let nodeCount: number = 0
    const nextKey = (): number => {
        nodeCount++
        return nodeCount
    }

    groupedDataPoints.forEach(group => {
        trNodes.push(
            <Tr key={nextKey()}>
                <Th colSpan={3} paddingTop={3} fontWeight={800} fontSize="14px">
                    {group.title}
                </Th>
            </Tr>
        )

        if (group.text) {
            trNodes.push(
                <Tr key={nextKey()}>
                    <Td colSpan={3}>{group.text}</Td>
                </Tr>
            )
        } else if (group.dataPoints) {
            group.dataPoints.forEach(kvp => {
                trNodes.push(
                    <Tr key={nextKey()}>
                        <Td colSpan={2}>{kvp.label}</Td>
                        <Td>{kvp.text || "--"}</Td>
                    </Tr>
                )
            })
        } else if (group.dataPointsCollection) {
            group.dataPointsCollection.forEach(row => {
                const dataPointsCount = row.dataPoints.length

                row.dataPoints.forEach((kvp, index) => {
                    trNodes.push(
                        <Tr key={nextKey()}>
                            {
                                index === 0
                                    ? <Th rowSpan={dataPointsCount} textAlign="center" verticalAlign="top">{row.index}.</Th>
                                    : null
                            }
                            <Td>{kvp.label}</Td>
                            <Td>{kvp.text || "--"}</Td>
                        </Tr>
                    )
                })
            })
        }
    })

    return (
        <Table size="sm" variant="striped">
            <Tbody children={trNodes} />
            <TableCaption placement="bottom">
                Redacted data points will be available after the client has accepted your quote.
            </TableCaption>
        </Table>
    )
}