import { createRequestSummaryInfo, RequestSummaryInfo } from "../common"
import { RequestModel } from "./types"

export const requestSummaryInfo = (data: RequestModel): RequestSummaryInfo => {
    const summary: RequestSummaryInfo = createRequestSummaryInfo(data)

    summary.fields.push({ label: "Purpose", value: data.proposer?.purposeOfTravel })

    const adultCount = Object.keys(data.adults).length
    const adultLabel = adultCount > 1 ? `${adultCount} Adults` : "1 Adult"

    const childCount = data.children ? Object.keys(data.children).length : 0

    if (childCount === 0) {
        summary.fields.push({ label: "Persons", value: adultLabel })
    } else if (childCount === 1) {
        summary.fields.push({ label: "Persons", value: `${adultLabel}, 1 child` })
    } else {
        summary.fields.push({ label: "Persons", value: `${adultLabel}, ${childCount} children` })
    }

    return summary
}
