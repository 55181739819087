import {
    Box,
    Button,
    Flex,
    Spacer,
} from "@chakra-ui/react"
import {
    ColorModeSwitcher,
    HeaderNavLink,
    Logo,
    Navbar
} from '.';

interface Props {
    contactUsLink?: string,
}

export const ActionsNavbar = ({
    contactUsLink = "/contact-us",
}: Props) => {
    return (
        <Navbar>
            <Flex align="center">
                <Box ml={-15}>
                    <HeaderNavLink href="/">
                        <Logo height={30} />
                    </HeaderNavLink>
                </Box>
            </Flex>
            <Flex maxW="720px" align="right" color="gray.400">
                <ColorModeSwitcher />
                <Spacer width={1} />
                <Button as="a" color="blue.300" href={contactUsLink} fontWeight="bold">
                    Talk to us
                </Button>
            </Flex>
        </Navbar>
    )
}
