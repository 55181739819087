import {
    createActionApi,
    createToastOptions,
    InsuranceProductBase,
    InsuranceRequestStage,
    RequestSummaryInfo
} from "../common"
import { RequestModel } from "./types"
import { requestSummaryInfo as requestSummaryInfoFn } from './utils'
import {
    adultMembersSection,
    childMembersSection,
    initialYourInfoSection,
    initialTravelInfoSection,
    productOptionsSection,
    proposerSection
} from "./uiConfigSections"

export class TravelInsuranceProduct extends InsuranceProductBase {
    name = "TravelInsurance"
    version = "v1"
    enabled = true
    actionApi = createActionApi("callableTravelInsuranceRequestActionFn")
    toastOptions = createToastOptions()

    initialForm() {
        return {
            title: "Travel Insurance",
            sections: [
                initialYourInfoSection,
                initialTravelInfoSection,
                productOptionsSection,
            ]
        }
    }

    preQuoteView(_stage: InsuranceRequestStage) {
        return {
            title: "Travel Insurance",
            sections: [
                proposerSection,
                productOptionsSection,
                adultMembersSection,
                childMembersSection
            ]
        }
    }

    requestSummaryInfo(data: RequestModel): RequestSummaryInfo {
        return requestSummaryInfoFn(data)
    }
}