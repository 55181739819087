import { ReactElement } from 'react'
import { BrandingPane, ColorModeSwitcher, Main, SignupPane } from '../../components'
import { loginWithGoogle, createUserWithEmail } from '../../auth'
import { Box } from '@chakra-ui/react'

export default function Index(): ReactElement {
  return (
    <>
      <Main px={0} display={{ md: "flex" }}>
        <Box position="absolute" right="8px" top="8px">
          <ColorModeSwitcher />
        </Box>
        <Box
          display={{
            base: "none",
            md: "block"
          }}
          width="full"
        >
          <BrandingPane />
        </Box>
        <Box
          px={4}
          width={{
            base: "100%",
            md: "48em"
          }}
        >
          <SignupPane
            onGoogleLogin={loginWithGoogle}
            onSignup={createUserWithEmail}
          />
        </Box>
      </Main>
    </>
  )
}
