import { useState } from 'react'
import { Box, Center, useDisclosure, useToast } from '@chakra-ui/react'
import { useLocation, useRoute } from 'wouter'
import {
    InfoDialog,
    InfoDialogProps,
    DefaultInfoDialogProps,
} from '../../components/InfoDialog'
import { getInsuranceProduct } from '../../products'
import { UIConfigFormCard } from '../../products/components/UIConfigFormCard'
import { createUIConfigDataValidator } from '../../products/common'

const NewRequest = () => {
    const toast = useToast()
    // eslint-disable-next-line
    const [_location, setLocation] = useLocation()
    // eslint-disable-next-line
    const [_match, params] = useRoute("/requests/n/:productName")
    const productName = params?.productName
    const {
        isOpen: infoDialogIsOpen,
        onOpen: openInfoDialog,
        onClose: closeInfoDialog } = useDisclosure()
    const [infoProps, setInfoProps] = useState<InfoDialogProps>(DefaultInfoDialogProps)


    if (!productName) {
        setLocation("/requests")
        return null
    }

    const insuranceProduct = getInsuranceProduct(productName)
    const uiConfig = insuranceProduct.initialForm()

    const requestDataIsValid = (requestData: any): boolean => {
        const validator = uiConfig.validator || createUIConfigDataValidator(uiConfig, true)
        return validator(requestData)
    }

    const onSubmit = async (values: any) => {
        try {
            if (!requestDataIsValid(values)) {
                toast(insuranceProduct.toastOptions.invalidRequestWarning(values))
                return
            }

            const id = await insuranceProduct.actionApi.createRequest(values)
            const infoProps: InfoDialogProps = {
                headerText: insuranceProduct.toastOptions.createSuccess().title?.toString(),
                bodyText: insuranceProduct.toastOptions.createSuccess().description?.toString() || "",
                buttonText: "OK",
                isOpen: infoDialogIsOpen,
                onClose: () => {
                    closeInfoDialog()

                    setLocation(`/requests/${id}`)
                },
            }
            setInfoProps(infoProps)
            openInfoDialog()
        } catch (error) {
            toast(insuranceProduct.toastOptions.createError(error))
        }
    }
    
    const onCancel = () => setLocation('/')

    return (
        <div>
            <Center>
                <Box width={600}>
                    <UIConfigFormCard
                        uiConfig={uiConfig}
                        onSave={onSubmit}
                        onCancel={onCancel}
                    />
                </Box>
                <InfoDialog
                    {...infoProps}
                    isOpen={infoDialogIsOpen}
                />
            </Center>
        </div>
    )
}

export default NewRequest
