import { Route, Switch } from 'wouter'
import New from "./New"

export default function Index() {
  return (
    <div>
      <Switch>
        <Route path="/claims/new" component={New} />
        <Route>
          Claims coming soon
        </Route>
      </Switch>
    </div>
  )
}
