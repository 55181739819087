import {
    InsuranceProductActionApi,
    InsuranceProductToastOptions,
    InsuranceProduct,
    InsuranceRequestStage,
    UIConfig
} from './types'
import { createRequestSummaryInfo } from './utils'

export { UIFieldType, DBDataType } from './types'
export type {
    UIConfig,
    UIConfigDataValidator,
    UIConfigSection,
    UIConfigSectionSingle,
    UIConfigSectionCollection,
    UIConfigSectionDataValidator,
    UIConfigField,
    UIConfigFieldDataValidator,
    RequestSummaryInfo,
    InsuranceRequestStage,
    RequestStageDecorator,
    InsuranceProductType,
    InsuranceRequestModel,
    InsuranceProduct,
} from './types'

export {
    missingTextHtml,
    decorateStage,
    createRequestSummaryInfo,
    uiConfigFieldsGetter,
    uiConfigFieldsToSimpleFormFieldProps,
    uiConfigFieldsToEmptyValues,
    textUIField,
    textareaUIField,
    numberUIField,
    phoneUIField,
    dateUIField,
    selectUIField,
    yesNoUIField,
    emailUIField,
    nameUIField,
    surnameUIField,
    othernamesUIField,
    phoneNoUIField,
    mobileNoUIField,
    addressUIField,
    dateOfBirthUIField,
    genderUIField,
} from './utils'

export { createActionApi } from './actionApi'
export { createToastOptions } from './toastOptions'
export {
    createUIConfigDataValidator,
    createUIConfigSectionDataValidator,
    createUIConfigFieldDataValidator
} from './validator'

const notImplementedUIConfig: UIConfig = {
    title: "Not Implemented",
    sections: []
}

export abstract class InsuranceProductBase implements InsuranceProduct {
    abstract name: string
    abstract version: string
    abstract enabled: boolean
    abstract actionApi: InsuranceProductActionApi
    abstract toastOptions: InsuranceProductToastOptions

    initialForm() {
        return notImplementedUIConfig
    }

    preQuoteView(_stage: InsuranceRequestStage) {
        return notImplementedUIConfig
    }

    requestSummaryInfo(data: any) {
        return createRequestSummaryInfo(data)
    }
}

export class NullInsuranceProduct extends InsuranceProductBase {
    name = "NullInsuranceProduct"
    version = "v1"
    enabled = false
    actionApi = {} as InsuranceProductActionApi
    toastOptions = {} as InsuranceProductToastOptions
}
