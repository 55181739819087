import {
    createActionApi,
    createToastOptions,
    InsuranceProductBase,
    InsuranceRequestStage,
    RequestSummaryInfo
} from "../common"
import { RequestModel } from "./types"
import { requestSummaryInfo as requestSummaryInfoFn } from './utils'
import { membersSection, proposerSection } from "./uiConfigSections"

export class GroupPersonalAccidentInsuranceProduct extends InsuranceProductBase {
    name = "GroupPersonalAccidentInsurance"
    version = "v1"
    enabled = true
    actionApi = createActionApi("callableGroupPersonalAccidentInsuranceRequestActionFn")
    toastOptions = createToastOptions()

    initialForm() {
        return {
            title: "Group Personal Accident Insurance",
            sections: [
                proposerSection
            ]
        }
    }

    preQuoteView(_stage: InsuranceRequestStage) {
        return {
            title: "Group Personal Accident Insurance",
            sections: [
                proposerSection,
                membersSection
            ]
        }
    }

    requestSummaryInfo(data: RequestModel): RequestSummaryInfo {
        return requestSummaryInfoFn(data)
    }
}